import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
// import { registration } from './registration.reducer';
import { user_Profile } from './users.reducer';
import { alert } from './alert.reducer';
import productInfoReducer from './productInfo.reducer';
import productListReducer from './productList.reducer';
import createBookingReducer from './createBooking.reducer';
import titleNameReducer from './titleName.reducer';
import listBookingReducer from './listBooking.reducer';

const rootReducer = combineReducers({
  authentication,
  // registration,
  user_Profile,
  alert,
  create_booking: createBookingReducer,
  productInfo: productInfoReducer,
  list_booking: listBookingReducer,
  productLists: productListReducer,
  TitleName: titleNameReducer,
});

export default rootReducer;