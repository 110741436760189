import React, { Component } from 'react';
import { connect } from "react-redux";
import { productListFetch } from "../../../ManageRedux/_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faClipboardList, faHdd } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import 'react-day-picker/lib/style.css';
import SubTable from './SubTable';
import "./CreateBooking.css";
import './Table.css'
import './AddAndSubtract.css';
import loading from '../../../Css/Logo/loading.gif';
import Pagination from "react-js-pagination";

class TableCreateBooking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            travelDate: undefined,
            departure: 'N/A',
            arrival: 'N/A',
            activePage: 1,
            offset: 0,
            keywordsSearch: '',
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    componentDidMount() {
        localStorage.setItem('loading', JSON.stringify('true'));
        const { offset, keywordsSearch } = this.state;
        this.props.productListFetch(offset, keywordsSearch);
    }

    handleSearchChange(e) {
        const { value } = e.target;
        const pageActive = 0;
        if (value.length === 0) {
            localStorage.setItem('loading', JSON.stringify('true'));
            const input = '';
            this.setState({ keywordsSearch: input });
            this.props.productListFetch(pageActive, input);
        }
        else if (value.length <= 2) {
            localStorage.setItem('loading', JSON.stringify('false'));
            this.setState({ keywordsSearch: value });
        }
        else if (value.length >= 3) {
            localStorage.setItem('loading', JSON.stringify('true'));
            this.setState({ keywordsSearch: value });
            this.props.productListFetch(pageActive, value);
        }
    }

    handlePageChange(pageNumber) {
        localStorage.setItem('loading', JSON.stringify('true'));
        const { offset, keywordsSearch } = this.state;
        const totalPage = this.props.productLists.total;
        let activeLastPage = (pageNumber - 1) * 10;
        let lastPage = activeLastPage - totalPage;
        let count = 0;
        if (pageNumber === 1) {
            if (offset === 0) {
                count = offset * 10;
                this.setState({ activePage: pageNumber, offset: count, loadingBarProgress: 0 });
                this.props.productListFetch(count, keywordsSearch);
            }
            else {
                this.setState({ offset: 0, activePage: pageNumber, loadingBarProgress: 0 })
                count = 0 * 10;
                this.props.productListFetch(count, keywordsSearch);
            }
        }
        else if (lastPage <= 1) {
            count = (pageNumber - 1) * 10;
            this.setState({ activePage: pageNumber, offset: count, loadingBarProgress: 0 });
            this.props.productListFetch(count, keywordsSearch);
        }
    }

    render() {
        // Get data from redux store => productList
        const data = this.props.productLists && this.props.productLists.data ? this.props.productLists.data : [];
        const checkRowSearch = this.props.productLists && this.props.productLists.total > 0 ? this.props.productLists.total : 0;
        const { activePage } = this.state;
        const statusLoading = localStorage.getItem('loading') ? JSON.parse(localStorage.getItem('loading')) : 'false';
        return (
            <div className="contents">
                {statusLoading === 'true' &&
                    <div className="row"><img src={loading} alt="loading_icon" className="img_loading_data" /></div>
                }
                <div className="row">
                    <div className="boxSearchProduct">
                        <div className="headSearchProduct" style={{ backgroundColor: "#0083b0" }}>
                            <h6 className="text-header-product">Search Product</h6>
                        </div>

                        <div className="containerBox">
                            <div className="boxline">
                                <div className="input-search">
                                    <span className="icons-search"><FontAwesomeIcon icon={faSearch} /></span>
                                    <input name="keywordsSearch" value={this.state.keywordsSearch} onChange={this.handleSearchChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="box-productlist">
                        <h6 className="text-product-head"><FontAwesomeIcon className="text-pos-product-head" icon={faClipboardList} />Product List</h6>
                    </div>
                    {/* <div className="posButton2">
                                <button className="button-product">Check out</button>
                            </div> */}
                </div>
                {checkRowSearch !== 0 &&
                    <div className="row">
                        <div className="box-default">
                            <table className="table-createBooking">
                                <thead className="thead-createBooking" style={{ background: "#00b4db" }}>
                                    <tr>
                                        <th>Ref ID</th>
                                        <th className="th-productName">Product Name</th>
                                        <th className="th-travelDate">Travel date</th>
                                        <th className="th-adult">Adult</th>
                                        <th className="th-child">Child <p className="text-product">( 3-9 age )</p></th>
                                        <th>Adult price</th>
                                        <th>Child price</th>
                                        <th>Total net price</th>
                                        <th className="th-null"><span className="text-null">""</span></th>
                                    </tr>
                                </thead>
                                <tbody className="tbody-createBooking">
                                    {data.map((e, index) => {
                                        return (
                                            <SubTable key={index} data={e} activePage={activePage} />
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="pagination">
                                {this.props.productLists.total &&
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={10}
                                        totalItemsCount={this.props.productLists.total}
                                        pageRangeDisplayed={5}
                                        innerClass="pagination"
                                        itemClass="page-item page-item-font"
                                        linkClass="page-link"
                                        prevPageText='prev'
                                        nextPageText='next'
                                        firstPageText='first'
                                        lastPageText='last'
                                        onChange={this.handlePageChange}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
                {checkRowSearch === 0 &&
                    <div className="row">
                        <div className="noData"><label className="text-noData">No Data<FontAwesomeIcon icon={faHdd} /></label></div>
                    </div>
                }
                <Helmet>
                    <style>{`.InputFrom .DayPickerInput {width:100%;}`}</style>
                </Helmet>
            </div >
        );
    }
}

function mapStateToProps({ productLists }) {
    let checkLoading = false;
    if (productLists.length === 0) {
        return { productLists, checkLoading };
    }
    else {
        checkLoading = true;
        return { productLists, checkLoading };
    }

}

export default connect(mapStateToProps, { productListFetch })(TableCreateBooking);