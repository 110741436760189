import React, { Component } from 'react';
import { connect } from "react-redux";
import './style.scss';
import { SELECT_TITLE_NAME } from '../../../../ManageRedux/_types/types';

class TitleName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            labelItem: null,
            typeDropdown: null,
            data: []
        };
    }

    checkType = (value) => {
        this.setState({
            typeDropdown: value
        });
    };
    showDropdown = (product_ref) => {
        this.setState({ isOpen: true });
        document.addEventListener("click", this.hideDropdown);
    };
    hideDropdown = () => {
        this.setState({ isOpen: false });
        document.removeEventListener("click", this.hideDropdown);
    };
    chooseItem = (value) => {
        if (this.state.labelItem !== value) {
            this.props.dispatch({
                type: SELECT_TITLE_NAME, payload: value
            })
            this.setState({
                labelItem: value,
                data: value
            })
        }
    };

    componentDidMount() {
        const TitleName = this.props.TitleName;
        if (TitleName && TitleName.length === 0) {
            this.props.dispatch({
                type: SELECT_TITLE_NAME, payload: "Mr"
            })
        }
    }

    render() {
        const titlename = [{ value: "Mr." }, { value: "Ms." }, { value: "Mrs." }];
        return (
            <div className={`selectSeats ${this.state.isOpen ? 'open' : ''}`}>
                <button className="dropdown-toggle" onClick={this.showDropdown}>
                    {this.state.labelItem !== null ? this.state.labelItem : 'Mr.'}
                </button>
                <ul className="dropdown-menu">
                    {titlename.map((e, index) => {
                        return (
                            <li
                                key={index}
                                value={e.value}
                                onClick={() => this.chooseItem(e.value)}
                            >
                                <p>{e.value}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

function mapStateToProps({ TitleName }) {
    return { TitleName }
}

export default connect(mapStateToProps)(TitleName);
