import { userConstants } from '../_constants';
import { USER_PROFILE_FETCH, REFRESH_TOKEN } from '../_types/types';

export function user_Profile(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case USER_PROFILE_FETCH:
      return action.payload;
    default:
      return state
  }
}

export function refreshToken(state = {}, action) {
  switch (action.type) {
    case REFRESH_TOKEN:
      return action.payload;
    default:
      return state
  }
}