import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import moment from 'moment';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import ModalAlert from '../CreateBooking/ModalAlert';
import './style.css';
import 'react-day-picker/lib/style.css';
import "../CreateBooking/Table.css";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handleTravelFromChange = this.handleTravelFromChange.bind(this);
        this.handleTravelToChange = this.handleTravelToChange.bind(this);
        this.state = {
            from: undefined,
            to: undefined,
            travelFrom: undefined,
            travelTo: undefined,
            isValid: false,
            message: '',
            payment_status: false,
        };
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem('alert')) {
            const alert = JSON.parse(localStorage.getItem('alert'));
            if (alert.status === 200000) {
                this.setState({ message: `Credit ${alert.data.credit_add} THB have been add to your account`, isValid: true, payment_status: true })
            }
            else if (alert.status === 400) {
                this.setState({ message: 'Duplicate Transaction Parameter', isValid: true, payment_status: false })
            }
        }
    }

    showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }

    showTravelMonth() {
        const { travelFrom, travelTo } = this.state;
        if (!travelFrom) {
            return;
        }
        if (moment(travelTo).diff(moment(travelFrom), 'months') < 2) {
            this.travelTo.getDayPicker().showMonth(travelFrom);
        }
    }

    handleFromChange(from) {
        // Change the from date and focus the "to" input field
        this.setState({ from });
    }

    handleToChange(to) {
        this.setState({ to }, this.showFromMonth);
    }

    handleTravelFromChange(travelFrom) {
        this.setState({ travelFrom });
    }

    handleTravelToChange(travelTo) {
        this.setState({ travelTo }, this.showTravelMonth);
    }

    closeModal() {
        this.setState({ isValid: false })
        localStorage.removeItem('alert');
    }

    render() {
        const { from, to, travelFrom, travelTo, isValid, message, payment_status } = this.state;
        const modifiers = { start: from, end: to };
        const FORMAT = 'YYYY/MM/DD';
        const data = this.props.user_Profile && this.props.user_Profile.data ? this.props.user_Profile.data : [];
        const bookings = data.bookings;
        const balance = data.balance;
        return (
            <div className="contents">
                <div className="row">
                    <ModalAlert message={message} action={isValid} status={payment_status} onClick={this.closeModal} />
                </div>
                <div className="row">
                    <div className="box">
                        <div className="boxLogo" style={{ backgroundColor: "#00b4db" }}>
                            <FontAwesomeIcon icon={faCartArrowDown} />
                        </div>
                        <div className="boxOrder">
                            <label className="text-header">Bookings</label>
                            {data && <label className="textHead">{new Intl.NumberFormat('th-TH', { maximumSignificantDigits: 3 }).format(bookings)}</label>}
                        </div>
                    </div>
                    <div className="box">
                        <div className="boxLogo" style={{ backgroundColor: "#0083b0" }}>
                            <FontAwesomeIcon icon={faDollarSign} />
                        </div>
                        <div className="boxOrder">
                            <label className="text-header">Current Credit</label>
                            {data && <label className="textHead">{new Intl.NumberFormat('th-TH', { maximumSignificantDigits: 3 }).format(balance)} THB</label>}
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="boxSearchBooking">
                        <div className="headSearchBooking" style={{ backgroundColor: "#0083b0" }}>
                            <h6 className="text-head">Search Booking</h6>
                        </div>
                        <div className="containerBox">
                            <div className="boxline">
                                <label>Booking Date From</label>
                                <div className="input-group">
                                    <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                    <div className="input-group-area">
                                        <div className="InputFrom">
                                            <DayPickerInput
                                                value={from}
                                                // iconPosition={'left'} icon={{ faCalendarAlt }}
                                                placeholder="yyyy-mm-dd"
                                                format={FORMAT}
                                                formatDate={formatDate}
                                                parseDate={parseDate}
                                                dayPickerProps={{
                                                    selectedDays: [from, { from, to }],
                                                    disabledDays: { after: to },
                                                    toMonth: to,
                                                    modifiers,
                                                    numberOfMonths: 1,
                                                    onDayClick: () => this.to.getInput().focus(),
                                                }}
                                                onDayChange={this.handleFromChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="boxline">
                                <label>Booking Date To</label>
                                <div className="input-group">
                                    <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                    <div className="input-group-area">
                                        <div className="InputFrom">
                                            <DayPickerInput
                                                ref={el => (this.to = el)}
                                                value={to}
                                                placeholder="yyyy-mm-dd"
                                                format={FORMAT}
                                                formatDate={formatDate}
                                                parseDate={parseDate}
                                                dayPickerProps={{
                                                    selectedDays: [from, { from, to }],
                                                    disabledDays: { before: from },
                                                    modifiers,
                                                    month: from,
                                                    fromMonth: from,
                                                    numberOfMonths: 1,
                                                }}
                                                onDayChange={this.handleToChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="boxline">
                                <label>Travel Date To</label>
                                <div className="input-group">
                                    <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                    <div className="input-group-area">
                                        <div className="InputFrom">
                                            <DayPickerInput
                                                value={travelFrom}
                                                // iconPosition={'left'} icon={{ faCalendarAlt }}
                                                placeholder="yyyy-mm-dd"
                                                format={FORMAT}
                                                formatDate={formatDate}
                                                parseDate={parseDate}
                                                dayPickerProps={{
                                                    selectedDays: [travelFrom, { from: travelFrom, to: travelTo }],
                                                    disabledDays: { after: travelTo },
                                                    toMonth: travelTo,
                                                    modifiers,
                                                    numberOfMonths: 1,
                                                    onDayClick: () => this.travelTo.getInput().focus(),
                                                }}
                                                onDayChange={this.handleTravelFromChange}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="boxline">
                                <label>Travel Date To</label>
                                <div className="input-group">
                                    <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                    <div className="input-group-area">
                                        <div className="InputFrom">
                                            <DayPickerInput
                                                ref={el => (this.travelTo = el)}
                                                value={travelTo}
                                                placeholder="yyyy-mm-dd"
                                                format={FORMAT}
                                                formatDate={formatDate}
                                                parseDate={parseDate}
                                                dayPickerProps={{
                                                    selectedDays: [travelFrom, { from: travelFrom, to: travelTo }],
                                                    disabledDays: { before: travelFrom },
                                                    modifiers,
                                                    month: travelFrom,
                                                    fromMonth: travelFrom,
                                                    numberOfMonths: 1,
                                                }}
                                                onDayChange={this.handletravelToChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="boxline">
                                <label>Customer Name</label>
                                <div className="icon">
                                    <label className="icons-search"><FontAwesomeIcon icon={faUser} /></label>
                                    <input placeholder="Enter Customer Name" />
                                </div>
                            </div>

                            <div className="boxline">
                                <div className="posButton-dashboard">
                                    <button className="button">Search</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="boxTable">
                        <div className="boxLeft">
                            <label className="checkbox">Paid and Confirmed
                                        <input type="checkbox" />
                                <span className="check_mark"></span>
                            </label>

                            <table>
                                <thead style={{ background: "#00b4db" }}>
                                    <tr>
                                        <th>Voucher ID</th>
                                        <th>Customer Name</th>
                                        <th>Booking date</th>
                                        <th>Travel date</th>
                                        <th>Departure</th>
                                        <th>Arrival</th>
                                    </tr>
                                </thead>
                                <tbody className="tbody-dashboard">
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="boxButton-allBooking">
                                <Link to="/booking/me">
                                    <button className="button-allBooking">All Booking</button>
                                </Link>
                            </div>
                        </div>

                        <div className="boxRight">
                            <label className="checkbox">Created
                                        <input type="checkbox" />
                                <span className="check_mark"></span>
                            </label>

                            <table>
                                <thead style={{ background: "#00b4db" }}>
                                    <tr>
                                        <th>Voucher ID</th>
                                        <th>Customer Name</th>
                                        <th>Booking date</th>
                                        <th>Travel date</th>
                                        <th>Departure</th>
                                        <th>Arrival</th>
                                    </tr>
                                </thead>
                                <tbody className="tbody-dashboard">
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                    <tr>
                                        <td>R90001230</td>
                                        <td>Max Musterman</td>
                                        <td>28-Sep-19 19:45:02</td>
                                        <td>30-Sep-19 20:00:00</td>
                                        <td>Bangkok Khaosan Rd.</td>
                                        <td>Koh Tao Maehaad Pier</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div> */}
                <Helmet>
                    <style>{`.InputFrom .DayPickerInput {width:100%;height:100%}`}</style>
                </Helmet>
            </div>
        );
    }
}

function mapStateToProps({ user_Profile }) {
    return { user_Profile };
}

export default connect(mapStateToProps)(Dashboard);