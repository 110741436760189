import React from "react";


const NotFoundView = () => {
    return(
        <div>
            {/* //Header */}
            <div className="container col-md-8 text-center">
                <h1 className="mt-5">404</h1>
                <h2 className="mb-4 text-danger">Not Found</h2>
                <p className="title mb-5">ขออภัยไ่พบหน้าที่คุณค้นหา ดูเหมือนว่าหน้าเว็บที่คุณพยายามเข้าถึงไม่มีอยู่อีกต่อไปหรืออาจจะย้ายไปยังหน้าเว็บเพจอื่น</p>
            </div>
            {/* //foodter */}
        </div>
    )
}

export default NotFoundView;