import { BOOKING_CREATE, FAILED_BOOKING, CLEAR_POPUP_ERROR_MESSAGE } from '../_types/types';
import axios from 'axios';

export const productCreate = ({ TitleName }, { fname, lname, email, phone,  ref,
    productInfo: { product_category, product_id, product_ref, travelDate, adult, child, localStoragePickUp, localStorageDropOff } }) => {
        console.log(localStorageDropOff)
    const user = localStorage.getItem('user')
    const userData = JSON.parse(user);
    let token = userData.access_token;
    const pathUrl = process.env.REACT_APP_API_URL + "bookings/create/one-way";
    const body = {
        'category': product_category,
        'product_id': product_id,
        'product_ref': product_ref,
        'travel_date': travelDate,
        'passengers': { 'adult': adult, 'child': child },
        'title_name': TitleName,
        'first_name': fname,
        'last_name': lname,
        'email': email,
        'phone': phone,
        'reference': ref,
        'pickup': localStoragePickUp && localStoragePickUp !== null ?  localStoragePickUp[product_ref]: "", 
        'dropoff': localStorageDropOff && localStorageDropOff !== null ? localStorageDropOff[product_ref] : ""
    }
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    return dispatch => {
        axios.post(pathUrl, body, { headers: headers }).then
            (res => {
                dispatch({ type: BOOKING_CREATE, payload: res.data })
            }).catch(({ response }) => {
                if (response.data) {
                    dispatch({ type: FAILED_BOOKING, payload: response.data })
                }
            });
    }
}

export const clearPopupErrorMessage = () => {
    return dispatch => {
        dispatch({ type: CLEAR_POPUP_ERROR_MESSAGE, payload: [] })
    }
}