import { PRODUCTINFO_FETCH, BOOKING_CREATE, FAILED_BOOKING } from '../_types/types'

export default function (state = [], action) {
    switch (action.type) {
        case PRODUCTINFO_FETCH:
            localStorage.setItem('productInfo', JSON.stringify(action.payload));
            return action.payload;
        case BOOKING_CREATE:
            return action.payload;
        case FAILED_BOOKING:
            return action.payload;
        default:
            return state;
    }
}