import React, { Component } from 'react';
import './style.css';
import SelectYear from './SelectYear/';
import { Link } from 'react-router-dom';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import "react-day-picker/lib/style.css";

import "../CreateBooking/Table.css";

class Invoice extends Component {
    render() {
        return (
            <div className="contents">
                <Container fluid>
                    <div className="row">
                        <div className="column">
                            <h4 className="text-header-booking"><FontAwesomeIcon className="text-pos-head-booking" icon={faFileAlt} />Invoices</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column">
                            <div className="box-text-invoice">
                                <p className="text-invoice1">Legal Name:</p>
                                <p className="text-invoice2 m-l-5">Direct Ferries</p>
                                <p className="text-invoice3">You can see the history of Invoices you've received from us here.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column">
                            <div className="box-year m-l-15">
                                <p className="text-year">Filter by year</p>
                                <SelectYear />
                            </div>

                            <div className="box-invoice">
                                <div className="box-header-invoice">
                                    <p className="text-invoice8 m-l-55">Invoice</p>
                                    <p className="text-invoice8 m-l-55">Descripton</p>
                                    <p className="text-invoice8 m-l-155">Due by</p>
                                    <p className="text-invoice8 m-l-100">Invoice amount</p>
                                    <p className="text-invoice8 m-l-30">Status</p>
                                </div>

                                <div className="box-invoice-card">
                                    <div className="box-invoice-head"></div>
                                    <div className="box-status"><FontAwesomeIcon className="text-icon" icon={faCheck} /></div>

                                    <div className="box-invoice-line1">
                                        <p className="text-invoice0">5554632110</p>
                                        <Link to="#" className="text-download">Download pdf</Link>
                                    </div>

                                    <div className="box-invoice-line2">
                                        <p className="text-invoice4">Period:Aug 1, 2009 --- Aug 31, 2019</p>
                                        <p className="text-invoice4">Date: Tue, Sept 2, 2019</p>
                                        <p className="text-invoice4">Invoice Type: Commission</p>
                                    </div>

                                    <div className="box-invoice-line3">
                                        <p className="text-invoice4">Mon,Sept 16,2019</p>
                                    </div>

                                    <div className="box-invoice-line4">
                                        <p className="text-invoice4">28,800 THB</p>
                                    </div>

                                    <div className="box-invoice-line5">
                                        <p className="text-invoice5">Paid:</p>
                                        <p className="text-invoice6">THB 28,800.00</p>
                                        <p className="text-invoice4">Date: Tue,Sept 12, 2019</p>
                                    </div>

                                </div>

                                <div className="box-invoice-card">
                                    <div className="box-invoice-head"></div>
                                    <div className="box-status"><FontAwesomeIcon className="text-icon" icon={faCheck} /></div>

                                    <div className="box-invoice-line1">
                                        <p className="text-invoice0">5554632110</p>
                                        <Link to="#" className="text-download">Download pdf</Link>
                                    </div>

                                    <div className="box-invoice-line2">
                                        <p className="text-invoice4">Period:Aug 1, 2009 --- Aug 31, 2019</p>
                                        <p className="text-invoice4">Date: Tue, Sept 2, 2019</p>
                                        <p className="text-invoice4">Invoice Type: Commission</p>
                                    </div>

                                    <div className="box-invoice-line3">
                                        <p className="text-invoice4">Mon,Sept 16,2019</p>
                                    </div>

                                    <div className="box-invoice-line4">
                                        <p className="text-invoice4">28,800 THB</p>
                                    </div>

                                    <div className="box-invoice-line5">
                                        <p className="text-invoice5">Paid:</p>
                                        <p className="text-invoice6">THB 28,800.00</p>
                                        <p className="text-invoice4">Date: Tue,Sept 12, 2019</p>
                                    </div>

                                </div>

                                <div className="box-invoice-card">
                                    <div className="box-invoice-head"></div>
                                    <div className="box-status"><FontAwesomeIcon className="text-icon" icon={faCheck} /></div>

                                    <div className="box-invoice-line1">
                                        <p className="text-invoice0">5554632110</p>
                                        <Link to="#" className="text-download">Download pdf</Link>
                                    </div>

                                    <div className="box-invoice-line2">
                                        <p className="text-invoice4">Period:Aug 1, 2009 --- Aug 31, 2019</p>
                                        <p className="text-invoice4">Date: Tue, Sept 2, 2019</p>
                                        <p className="text-invoice4">Invoice Type: Commission</p>
                                    </div>

                                    <div className="box-invoice-line3">
                                        <p className="text-invoice4">Mon,Sept 16,2019</p>
                                    </div>

                                    <div className="box-invoice-line4">
                                        <p className="text-invoice4">28,800 THB</p>
                                    </div>

                                    <div className="box-invoice-line5">
                                        <p className="text-invoice5">Paid:</p>
                                        <p className="text-invoice6">THB 28,800.00</p>
                                        <p className="text-invoice4">Date: Tue,Sept 12, 2019</p>
                                    </div>

                                </div>

                                <div className="box-invoice-card">
                                    <div className="box-invoice-head"></div>
                                    <div className="box-status"><FontAwesomeIcon className="text-icon" icon={faCheck} /></div>

                                    <div className="box-invoice-line1">
                                        <p className="text-invoice0">5554632110</p>
                                        <Link to="#" className="text-download">Download pdf</Link>
                                    </div>

                                    <div className="box-invoice-line2">
                                        <p className="text-invoice4">Period:Aug 1, 2009 --- Aug 31, 2019</p>
                                        <p className="text-invoice4">Date: Tue, Sept 2, 2019</p>
                                        <p className="text-invoice4">Invoice Type: Commission</p>
                                    </div>

                                    <div className="box-invoice-line3">
                                        <p className="text-invoice4">Mon,Sept 16,2019</p>
                                    </div>

                                    <div className="box-invoice-line4">
                                        <p className="text-invoice4">28,800 THB</p>
                                    </div>

                                    <div className="box-invoice-line5">
                                        <p className="text-invoice5">Paid:</p>
                                        <p className="text-invoice6">THB 28,800.00</p>
                                        <p className="text-invoice4">Date: Tue,Sept 12, 2019</p>
                                    </div>

                                </div>

                                <div className="box-invoice-card">
                                    <div className="box-invoice-head"></div>
                                    <div className="box-status"><FontAwesomeIcon className="text-icon" icon={faCheck} /></div>

                                    <div className="box-invoice-line1">
                                        <p className="text-invoice0">5554632110</p>
                                        <Link to="#" className="text-download">Download pdf</Link>
                                    </div>

                                    <div className="box-invoice-line2">
                                        <p className="text-invoice4">Period:Aug 1, 2009 --- Aug 31, 2019</p>
                                        <p className="text-invoice4">Date: Tue, Sept 2, 2019</p>
                                        <p className="text-invoice4">Invoice Type: Commission</p>
                                    </div>

                                    <div className="box-invoice-line3">
                                        <p className="text-invoice4">Mon,Sept 16,2019</p>
                                    </div>

                                    <div className="box-invoice-line4">
                                        <p className="text-invoice4">28,800 THB</p>
                                    </div>

                                    <div className="box-invoice-line5">
                                        <p className="text-invoice5">Paid:</p>
                                        <p className="text-invoice6">THB 28,800.00</p>
                                        <p className="text-invoice4">Date: Tue,Sept 12, 2019</p>
                                    </div>

                                </div>

                                <div className="box-invoice-card">
                                    <div className="box-invoice-head2"></div>
                                    <div className="box-status2"><FontAwesomeIcon className="text-icon" icon={faTimes} /></div>

                                    <div className="box-invoice-line1">
                                        <p className="text-invoice0">5554632110</p>
                                        <Link to="#" className="text-download">Download pdf</Link>
                                    </div>

                                    <div className="box-invoice-line2">
                                        <p className="text-invoice4">Period:Aug 1, 2009 --- Aug 31, 2019</p>
                                        <p className="text-invoice4">Date: Tue, Sept 2, 2019</p>
                                        <p className="text-invoice4">Invoice Type: Commission</p>
                                    </div>

                                    <div className="box-invoice-line3">
                                        <p className="text-invoice4">-</p>
                                    </div>

                                    <div className="box-invoice-line4">
                                        <p className="text-invoice4">28,800 THB</p>
                                    </div>

                                    <div className="box-invoice-line5">
                                        <p className="text-invoice5">Unpaid:</p>
                                        <p className="text-invoice7">THB 28,800.00</p>
                                        <p className="text-invoice4">Date: Tue,Sept 12, 2019</p>
                                    </div>
                                </div>

                                <div className="box-invoice-card">
                                    <div className="box-invoice-head2"></div>
                                    <div className="box-status2"><FontAwesomeIcon className="text-icon" icon={faTimes} /></div>

                                    <div className="box-invoice-line1">
                                        <p className="text-invoice0">5554632110</p>
                                        <Link to="#" className="text-download">Download pdf</Link>
                                    </div>

                                    <div className="box-invoice-line2">
                                        <p className="text-invoice4">Period:Aug 1, 2009 --- Aug 31, 2019</p>
                                        <p className="text-invoice4">Date: Tue, Sept 2, 2019</p>
                                        <p className="text-invoice4">Invoice Type: Commission</p>
                                    </div>

                                    <div className="box-invoice-line3">
                                        <p className="text-invoice4">-</p>
                                    </div>

                                    <div className="box-invoice-line4">
                                        <p className="text-invoice4">28,800 THB</p>
                                    </div>

                                    <div className="box-invoice-line5">
                                        <p className="text-invoice5">Unpaid:</p>
                                        <p className="text-invoice7">THB 28,800.00</p>
                                        <p className="text-invoice4">Date: Tue,Sept 12, 2019</p>
                                    </div>
                                </div>

                                <div className="box-invoice-card">
                                    <div className="box-invoice-head2"></div>
                                    <div className="box-status2"><FontAwesomeIcon className="text-icon" icon={faTimes} /></div>

                                    <div className="box-invoice-line1">
                                        <p className="text-invoice0">5554632110</p>
                                        <Link to="#" className="text-download">Download pdf</Link>
                                    </div>

                                    <div className="box-invoice-line2">
                                        <p className="text-invoice4">Period:Aug 1, 2009 --- Aug 31, 2019</p>
                                        <p className="text-invoice4">Date: Tue, Sept 2, 2019</p>
                                        <p className="text-invoice4">Invoice Type: Commission</p>
                                    </div>

                                    <div className="box-invoice-line3">
                                        <p className="text-invoice4">-</p>
                                    </div>

                                    <div className="box-invoice-line4">
                                        <p className="text-invoice4">28,800 THB</p>
                                    </div>

                                    <div className="box-invoice-line5">
                                        <p className="text-invoice5">Unpaid:</p>
                                        <p className="text-invoice7">THB 28,800.00</p>
                                        <p className="text-invoice4">Date: Tue,Sept 12, 2019</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}
export default Invoice;