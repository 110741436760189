import React, { Component } from 'react';
import NavBar from '../Parts/NavBar';
import SideDrawer from '../Parts/SideDrawer';
import CardTable from '../Components/Dashboard';

class DashboardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: '',
      lastScrollPos: 0
    }
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll(event) {
    if (this.state.lastScrollPos > event.currentTarget.scrollTop) {
      console.log(this.state.direction);
      this.setState({
        direction: 'top',
        lastScrollPos: event.currentTarget.scrollTop
      });
    } else if (this.state.lastScrollPos < event.currentTarget.scrollTop) {
      console.log(this.state.direction);
      this.setState({
        direction: 'bottom',
        lastScrollPos: event.currentTarget.scrollTop
      });
    }
  }

  render() {
    const { direction } = this.state;
    console.log(direction);
    return (
      <div className="container-fluid">
        <NavBar />
        <SideDrawer />
        <CardTable />
      </div>
    );
  }
}

export default DashboardView;