import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
// import { faDesktop, faEnvelope, faPoll, faFileAlt, faFileMedical, faWrench, faBriefcase, faWallet, faSearch, faMapMarkedAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faDesktop, faFileMedical, faSearch, faSignOutAlt, faWallet } from '@fortawesome/free-solid-svg-icons';

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #e0f7fa;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
      width: 100%;
    }

  .Link {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 1.4rem 0;
    font-weight: bold;
    letter-spacing: 0.1rem;
    color: #444444;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 0.9rem;
      text-align: center;
      padding: 0.85rem 0;
    }

    &:hover {
      color: #00b4db;
    }
  }
`

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

const Menu = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <Link className="Link" to="/booking/dashboard"><FontAwesomeIcon icon={faDesktop} /> Dashboard</Link>
      <Link className="Link" to="/booking/create"><FontAwesomeIcon icon={faFileMedical} /> Create Booking</Link>
      <Link className="Link" to="/booking/me"><FontAwesomeIcon icon={faSearch} /> My Booking</Link>
      <Link className="Link" to="/booking/payment"><FontAwesomeIcon icon={faWallet} />  Credit Top up</Link>
      {/* <Link className="Link" to="Calendarbooking"><FontAwesomeIcon icon={faCalendarAlt} /> Calendar of booking</Link>
      <Link className="Link" to="SalesReport"><FontAwesomeIcon icon={faPoll} /> Sales report</Link>
      <Link className="Link" to="Invoices"><FontAwesomeIcon icon={faFileAlt} /> Invoices</Link>
      <Link className="Link" to="POIAndStation"><FontAwesomeIcon icon={faMapMarkedAlt} />  POI and Stations</Link>
      <Link className="Link" to="#link"><FontAwesomeIcon icon={faWrench} /> Profile and Settings</Link>
      <Link className="Link" to="#link"><FontAwesomeIcon icon={faBriefcase} /> FAQ</Link>
      <Link className="Link" to="#link"><FontAwesomeIcon icon={faEnvelope} /> Contact up</Link> */}
      <Link className="Link" to="/login" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</Link>
    </StyledMenu>
  )
}

const StyledBurger = styled.button`
  position: absolute;
  top: 10px;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? '#444444' : '#ffff'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

const DrawerToggle = () => {
  const [open, setOpen] = React.useState(false);
  const node = React.useRef();
  return (
    <div>
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
    </div>
  )
}
export default DrawerToggle;

const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  },
    [ref, handler],
  );
};