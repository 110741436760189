import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faWallet } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import './style.css';
import paypal from '../../../Css/Logo/paypal.png';
import kasikorn from '../../../Css/Logo/KBANK.png';
import loading from '../../../Css/Logo/loading.gif';

class CreditTopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            isLoading: false,
        }
        this.handleInputPayPalChange = this.handleInputPayPalChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleInputPayPalChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    submit(e) {
        e.preventDefault();
        const { amount } = this.state;
        if (amount !== '') {
            this.setState({ isLoading: true })
            document.getElementById("sendform").submit();
        }
    }

    render() {
        const data = this.props.user_Profile && this.props.user_Profile.data ? this.props.user_Profile.data : [];
        const balance = data.balance;
        const { isLoading } = this.state;
        return (
            <form id="sendform" action="https://sb.api.adv-tour.com/v2/api/payment/topup" method="post">
                <div className="contents">
                    <div className="row">
                        <div className="box-creditHeader">
                            <h6 className="text-header-booking"><FontAwesomeIcon className="text-pos-head-booking" icon={faWallet} />Credit Top up</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="box box-margin">
                            <div className="boxLogo" style={{ backgroundColor: "#0083b0" }}>
                                <FontAwesomeIcon icon={faDollarSign} />
                            </div>
                            <div className="boxOrder">
                                <label className="text-header">Current Credit</label>
                                {data && <label className="textHead">{new Intl.NumberFormat('th-TH', { maximumSignificantDigits: 3 }).format(balance)} THB</label>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="box-credit-body">
                            <span className="text-header-paypal">Top up Credit via Paypal / Credit Card</span>
                            <div className="card-credit">
                                <div className="box-logo">
                                    <img src={paypal} alt="logo_image" className="logo-img-credit-paypal" />
                                </div>
                                <div className="box-paypal">
                                    <label className="text-paypal">Amount</label>
                                    <div className="icon-paypal">
                                        <span className="icons"><FontAwesomeIcon icon={faPaypal} /></span>
                                        <input placeholder="Enter Amount" name="amount" value={this.state.amount} onChange={this.handleInputPayPalChange} />
                                        <input type="hidden" name="reference" value={data.reference} />
                                    </div>
                                    <label className="text-paypal text-position">Currency</label>
                                    <div className="icon-paypal">
                                        <span className="icons"><FontAwesomeIcon icon={faDollarSign} /></span>
                                        <input placeholder="THB" name="currency" value="THB" disabled/>
                                    </div>
                                    <button className="button-paypal button-position" type="submit" onClick={this.submit}>Buy Now</button>
                                </div>
                            </div>
                        </div>

                        <div className="box-credit-body box-pos">
                            <span className="text-header-kbank">Top up Credit via Bank transfer</span>
                            <div className="card-credit">
                                <img src={kasikorn} alt="logo_image" className="logo-img-credit-kbank" />
                                <div className="box-kbank">
                                    <h6 className="text-kbank">S.S ADV. Co.,Ltd.</h6>
                                    <p className="text-kbank-body text-position">Kasikornbank Bank Public Company Limited</p>
                                    <p className="text-kbank-body">Bank account name : S.S ADV LIMITED PARTNERSHIP</p>
                                    <p className="text-kbank-body ">Bank account : 470-2-30972-2</p>
                                    <p className="text-kbank-body">Saving account</p>
                                    <p className="text-kbank-body text-position">172 Khaosan road, Taladyod, Pranakorn Bangkok 10200 Thailand</p>
                                    <p className="text-kbank-body">TAT License : 12/00766</p>
                                    <p className="text-kbank-body">Tel. +66 2629 3415, + 66 2282 0418</p>
                                    <p className="text-kbank-body">Fax. + 66 2282 3830</p>
                                    <p className="text-kbank-body">Email :info@adv-tour.com</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            {isLoading &&
                                <img src={loading} alt="loading_icon" className="img_loading" />
                            }
                        </div>
                    </div>
                </div>
            </form >
        );
    }
}
function mapStateToProps({ user_Profile }) {
    return { user_Profile };
}
export default connect(mapStateToProps)(CreditTopup);