import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { Login } from "./Login";
import DashboardView from "./View/DashboardView";
import { connect } from 'react-redux';
import CreateBookingView from "./View/CreateBookingView";
import NotFoundView from "./View/NotFoundView";
import CreditTopupView from "./View/CreditTopupView";
import MyBookingView from "./View/MyBookingView";
import CalendarBookingView from "./View/CalendarBookingView";
import InvoiceView from "./View/InvoiceView";
import PoiStationView from "./View/POIAndStationView";
import SalesReportView from './View/SalesReportView';
import { history } from './ManageRedux/_helpers';
import { alertActions } from './ManageRedux/_actions';
import { PrivateRoute } from './ManageRedux/_components';
import AddCart from './View/CreateBookingView/AddCart';
import FormLoad from './View/Components/CreditTopup/FormLoad';

class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/booking/dashboard" component={DashboardView} />
          <PrivateRoute exact path="/booking/create" component={CreateBookingView} />
          <PrivateRoute exact path="/booking/cart/:id" component={AddCart} />
          <PrivateRoute exact path="/booking/payment" component={CreditTopupView} />
          <PrivateRoute exact path="/booking/me" component={MyBookingView} />
          <PrivateRoute exact path="/booking/calendar" component={CalendarBookingView} />
          <PrivateRoute exact path="/booking/invoices" component={InvoiceView} />
          <PrivateRoute exact path="/booking/map" component={PoiStationView} />
          <PrivateRoute exact path="/booking/report" component={SalesReportView} />
          <PrivateRoute path="/payment/verify" component={FormLoad} />
          <Route path="/" component={Login} />
          <Route path="*" component={NotFoundView} />
        </Switch>
      </Router>

    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };