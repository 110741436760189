import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

class ModalAlert extends Component {
    render() {
        const { message, action, status } = this.props;
        return (
            <div>
                <Modal show={action} onHide={this.props.onClick}>
                    <Modal.Header closeButton className={status === true ? "alert-success boxModalAlert" : "alert-danger boxModalAlert"} >
                        <label>{message}</label>
                    </Modal.Header>
                </Modal>
            </div >
        )
    }
}
export default ModalAlert;