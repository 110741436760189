import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

const data = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
    { name: 'Page H', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page I', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page J', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page K', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page L', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page M', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page N', uv: 3490, pv: 4300, amt: 2100 },
];
class Chart extends Component {
    render() {
        return (
            <ResponsiveContainer width="100%" height={260}>
                <BarChart width={1200} height={300} data={data}
                    margin={{ top: 5, right: 5, left: 5, bottom: 5 }} barCategoryGap='2%' barGap={0.5}>
                    <CartesianGrid strokeDasharray="0" vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="bottom" />
                    <Bar dataKey="pv" fill="#0083b0" />
                </BarChart>
            </ResponsiveContainer>
        );
    }
}
export default Chart;