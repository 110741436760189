import React, { Component } from 'react';
import './style.css';
import DepartureSelect from './Dropdown';
import Main from './googlemap';
import icon_thailand from '../../../Css/Logo/thailand_icon.png';

import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faTrain, faShip, faBus } from '@fortawesome/free-solid-svg-icons';

// import { Map, GoogleApiWrapper } from 'google-maps-react';

// import Helmet from 'react-helmet';
// import moment from 'moment';
// import { formatDate, parseDate } from 'react-day-picker/moment';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import "../CreateBooking/Table.css";

class POI extends Component {
    render() {
        return (
            <div className="contents">
                <Container fluid>
                    <div className="row">
                        <div className="column">
                            <h4 className="text-header-booking"><FontAwesomeIcon className="text-pos-head-booking" icon={faClipboardList} />POI and Stations</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column">
                            <div className="box-pos-poi"><DepartureSelect /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column">
                            <div className="box-list">
                                <table className="table-poi">
                                    <tbody className="tbody-poi">
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faBus} />Khaosan Road</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Bangkok</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Matapon Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Chumphon</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Maehhad Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Koh Tao</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Tongsala Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Koh Phangan</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Nathon Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Koh Samui</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Donsak Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Suratthani</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faBus} />Suratthani Town</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Suratthani</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faTrain} />Suratthani Train</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Suratthani</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faBus} />Andaman Wave Office</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Krabi</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faBus} />Khaosan Road</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Bangkok</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Matapon Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Chumphon</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Maehhad Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Koh Tao</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Tongsala Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Koh Phangan</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Nathon Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Koh Samui</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Donsak Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Suratthani</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faBus} />Suratthani Town</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Suratthani</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faTrain} />Suratthani Train</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Suratthani</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faBus} />Andaman Wave Office</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Krabi</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faBus} />Khaosan Road</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Bangkok</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Matapon Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Chumphon</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Maehhad Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Koh Tao</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Tongsala Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Koh Phangan</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Nathon Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Koh Samui</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faShip} />Donsak Pier</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Suratthani</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faBus} />Suratthani Town</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Suratthani</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faTrain} />Suratthani Train</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Suratthani</td>
                                        </tr>
                                        <tr className="tr-poi">
                                            <td className="td-poi"><FontAwesomeIcon className="mr-2 text-icon" icon={faBus} />Andaman Wave Office</td>
                                            <td className="td-poi"><img src={icon_thailand} alt="" className="icon-thailand" />Krabi</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="box-map">
                                <Main />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}
export default POI;