import { authHeader } from '../_helpers';
import axios from 'axios';

export const userService = {
    login,
    // register,
    getAll,
    getById,
    // update,
    // delete: _delete
};

function login(username, password) {
    const pathUrl = process.env.REACT_APP_API_URL + "auth/agent/login";
    const headers = { 'Content-Type': 'application/json' };
    const body = {
        ag_email: username,
        password: password
    };
    return axios.post(pathUrl, body, { headers: headers })
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            console.log(user.data)
            localStorage.setItem('user', JSON.stringify(user.data));
            return user;
        });
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

// function register(user) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
// }

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}