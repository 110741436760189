import React, { Component } from 'react';

import NavBar from '../Parts/NavBar';
import SideDrawer from '../Parts/SideDrawer';
import Calendar from '../Components/Calendar';

class CalendarBookingView extends Component {
  render() {
    return (
      <div className="container-fluid">
        <NavBar />
        <SideDrawer />
        <Calendar />
      </div>
    );
  }
}

export default CalendarBookingView;
