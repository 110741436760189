import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo2 from "../../../Css/Logo/Logo2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
// import { faDesktop, faEnvelope, faPoll, faFileAlt, faFileMedical, faWrench, faBriefcase, faWallet, faSearch, faMapMarkedAlt }
//   from '@fortawesome/free-solid-svg-icons';
import { faDesktop, faFileMedical, faSearch, faWallet } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { history } from '../../../ManageRedux/_helpers';
class SideDrawer extends Component {
  constructor(props) {
    super(props);
    this.switchRoute = this.switchRoute.bind(this);
  }

  switchRoute(location) {
    switch (location) {
      case "dashboard":
        history.push('/booking/dashboard');
        break;
      case "create":
        history.push('/booking/create');
        break;
      case "me":
        history.push('/booking/me');
        break;
      case "payment":
        history.push('/booking/payment');
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <div className="side-drawer">
        <div className="side-drawer-logo">
          <Link to="/booking/dashboard"><img src={logo2} alt="logo_image" className="logo-img" /></Link>
        </div>
        <ul className="side-drawer-content">
          <li onClick={() => this.switchRoute('dashboard')}><FontAwesomeIcon icon={faDesktop} />Dashboard</li>
          <li onClick={() => this.switchRoute('create')}><FontAwesomeIcon icon={faFileMedical} />Create Booking</li>
          <li onClick={() => this.switchRoute('me')}><FontAwesomeIcon icon={faSearch} />My Booking</li>
          <li onClick={() => this.switchRoute('payment')}><FontAwesomeIcon icon={faWallet} /> Credit Top up</li>
          {/* <li><Link to="/booking/calendar"><FontAwesomeIcon icon={faCalendarAlt} /> Calendar of booking</Link></li>
          <li><Link to="/booking/report"><FontAwesomeIcon icon={faPoll} /> Sales report</Link></li>
          <li><Link to="/booking/invoices"><FontAwesomeIcon icon={faFileAlt} /> Invoices</Link></li>
          <li><Link to="/booking/payment"><FontAwesomeIcon icon={faWallet} />  Credit Top up</Link></li>
          <li><Link to="/booking/map"><FontAwesomeIcon icon={faMapMarkedAlt} />  POI and Stations</Link></li>
          <li><Link to="#link"><FontAwesomeIcon icon={faWrench} /> Profile and Settings</Link></li>
          <li><Link to="#link"><FontAwesomeIcon icon={faBriefcase} /> FAQ</Link></li>
          <li><Link to="#link"><FontAwesomeIcon icon={faEnvelope} /> Contact up</Link></li> */}
        </ul>
      </div>
    );
  }
}
export default SideDrawer;