import React, { Component } from 'react';
import './style.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';

class PickUpDropOff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pickUpIsOpen: false,
            dropOffIsOpen: false,
            labelPickUp: '',
            labelDropOff: '',
            dropOff_location: [],
            pickUp_location: [],
        };
        this.showPicUpDropdown = this.showPicUpDropdown.bind(this);
        this.hidePickUpDropdown = this.hidePickUpDropdown.bind(this);
        this.showDropOffDropdown = this.showDropOffDropdown.bind(this);
        this.hideDropOffDropdown = this.hideDropOffDropdown.bind(this);
    }

    componentDidMount() {
        this.fetchPickUpDropOff();
        localStorage.removeItem('pickUp');
        localStorage.removeItem('dropOff');
    }

    fetchPickUpDropOff() {
        const { productRef } = this.props;
        const user = localStorage.getItem('user')
        const userData = JSON.parse(user);
        let token = userData.access_token;
        const pathUrl = process.env.REACT_APP_API_URL + `products/info/${productRef}`;
        const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
        return axios.get(pathUrl, { headers: headers })
            .then(res => {
                this.setState({
                    pickUp_location: res.data.data.pickup_location,
                    dropOff_location: res.data.data.drop_off_location
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    showPicUpDropdown() {
        this.setState({ pickUpIsOpen: true });
        document.addEventListener("click", this.hidePickUpDropdown);
    }

    hidePickUpDropdown() {
        this.setState({ pickUpIsOpen: false });
        document.removeEventListener("click", this.hidePickUpDropdown);
    }

    pickUpChooseItem(value, productRef) {
        let localStoragePickUp = JSON.parse(localStorage.getItem('pickUp'));

        // If no existing data, create an array
        // Otherwise, convert the localStorage string to an array
        localStoragePickUp = localStoragePickUp ? localStoragePickUp : {};

        // Add new data to localStorage Array
        localStoragePickUp[productRef] = value;

        // Save back to localStorage
        localStorage.setItem('pickUp', JSON.stringify(localStoragePickUp));

        if (this.state.labelPickUp !== value) {
            this.setState({
                labelPickUp: value
            })
        }
        // console.log(localStoragePickUp[productRef]);
    };

    showDropOffDropdown() {
        this.setState({ dropOffIsOpen: true });
        document.addEventListener("click", this.hideDropOffDropdown);
    }

    hideDropOffDropdown() {
        this.setState({ dropOffIsOpen: false });
        document.removeEventListener("click", this.hideDropOffDropdown);
    }

    dropOffChooseItem(value,productRef) {
        let localStorageDropOff = JSON.parse(localStorage.getItem('dropOff'));

        // If no existing data, create an array
        // Otherwise, convert the localStorage string to an array
        localStorageDropOff = localStorageDropOff ? localStorageDropOff : {};

        // Add new data to localStorage Array
        localStorageDropOff[productRef] = value;

        // Save back to localStorage
        localStorage.setItem('dropOff', JSON.stringify(localStorageDropOff));
        
        if (this.state.labelDropOff !== value) {
            this.setState({
                labelDropOff: value
            })
        }
    };



    render() {
        const { pickUp_location, dropOff_location, labelPickUp, labelDropOff, pickUpIsOpen, dropOffIsOpen } = this.state;
        const { productRef } = this.props;
        return (
            <div className="boxSelectPickUp_DropOff">
                <div className={`boxPickUp_DropOff ${pickUp_location.length === 0 ? 'boxDisplayNone' : ''}`}>
                    <div className="select-menu" >
                        <button className="menu-toggle" type="button" onClick={this.showPicUpDropdown}>
                            <span className="text-button-dropdown">{labelPickUp !== '' ? labelPickUp : 'PickUp'}</span>
                            <FontAwesomeIcon className="icon-ArrowDown" icon={faSortDown} />
                        </button>
                        {pickUpIsOpen &&
                            <ul className="block-menu">
                                {pickUp_location.length > 0 &&
                                    pickUp_location.map((e, index) => {
                                        return (
                                            <li
                                                key={index}
                                                value={e.name}
                                                onClick={() => this.pickUpChooseItem(e.name, productRef)}
                                            >
                                                <p>{e.name}</p>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        }
                    </div>
                </div>
                <div className={`boxPickUp_DropOff ${dropOff_location.length === 0 ? 'boxDisplayNone' : ''}`}>
                    <ul>
                        <div className="select-menu">
                            <button className="menu-toggle" type="button" onClick={this.showDropOffDropdown}>
                                <span className="text-button-dropdown">{labelDropOff !== '' ? labelDropOff : 'DropOff'}</span>
                                <FontAwesomeIcon className="icon-ArrowDown" icon={faSortDown} />
                            </button>
                            {dropOffIsOpen &&
                                <ul className="block-menu">
                                    {dropOff_location.length > 0 &&
                                        dropOff_location.map((e, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    value={e.name}
                                                    onClick={() => this.dropOffChooseItem(e.name,productRef)}
                                                >
                                                    <p>{e.name}</p>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            }

                        </div>
                    </ul>
                </div>
            </div>
        );
    }
}

export default PickUpDropOff;



