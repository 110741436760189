import React, { Component } from 'react';
import { connect } from "react-redux";
import { listBookingFetch, searchListBooking } from "../../../ManageRedux/_actions";
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faUser } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import Helmet from 'react-helmet';
import { formatDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import "../CreateBooking/Table.css";
import SubTableList from './SubTableBookingList';
import Pagination from "react-js-pagination";
import ModalAlert from '../CreateBooking/ModalAlert';

class ListBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookingFrom: undefined,
            bookingTo: undefined,
            travelFrom: undefined,
            travelTo: undefined,
            customer: '',
            activePage: 1,
            offset: 0,
            showBooking: true,
            showTravel: true,
            isValid: false,
        };

        this.handleBookingFromChange = this.handleBookingFromChange.bind(this);
        this.handleBookingToChange = this.handleBookingToChange.bind(this);
        this.handleTravelFromChange = this.handleTravelFromChange.bind(this);
        this.handleTravelToChange = this.handleTravelToChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const { offset } = this.state;
        const data = [{ bookingFrom: 'undefined', bookingTo: 'undefined', travelFrom: 'undefined', travelTo: 'undefined', customer: '' }];
        localStorage.setItem('ListBookingSearch', JSON.stringify(data));
        this.props.listBookingFetch(offset);
    }

    showBookingMonth() {
        const { bookingFrom, bookingTo } = this.state;
        if (!bookingFrom) {
            return;
        }
        if (moment(bookingTo).diff(moment(bookingFrom), 'months') < 2) {
            this.bookingTo.getDayPicker().showMonth(bookingFrom);
        }
    }

    showTravelMonth() {
        const { travelFrom, travelTo } = this.state;
        if (!travelFrom) {
            return;
        }
        if (moment(travelTo).diff(moment(travelFrom), 'months') < 2) {
            this.travelTo.getDayPicker().showMonth(travelFrom);
        }
    }

    handleBookingFromChange(bookingFrom) {
        if (bookingFrom === undefined) {
            this.setState({ showTravel: true })
        }
        else {
            this.setState({ showTravel: false })
        }
        // Change the from date and focus the "to" input field
        this.setState({ bookingFrom: bookingFrom });
    }

    handleBookingToChange(bookingTo) {
        if (bookingTo === undefined) {
            this.setState({ showTravel: true })
        }
        else {
            this.setState({ showTravel: false })
        }
        this.setState({ bookingTo: bookingTo }, this.showBookingMonth);
    }

    handleTravelFromChange(travelFrom) {
        if (travelFrom === undefined) {
            this.setState({ showBooking: true })
        }
        else {
            this.setState({ showBooking: false })
        }
        this.setState({ travelFrom: travelFrom });
    }

    handleTravelToChange(travelTo) {
        if (travelTo === undefined) {
            this.setState({ showBooking: true })
        }
        else {
            this.setState({ showBooking: false })
        }
        this.setState({ travelTo: travelTo }, this.showTravelMonth);
    }

    handlePageChange(pageNumber) {
        const { offset } = this.state;
        const totalPage = this.props.pageTotal ? this.props.pageTotal : 0;
        let activeLastPage = (pageNumber - 1) * 10;
        let lastPage = activeLastPage - totalPage;
        let count = 0;
        if (pageNumber === 1) {
            if (offset === 0) {
                count = offset * 10;
                this.setState({ activePage: pageNumber, offset: count });
                this.props.listBookingFetch(count);
            }
            else {
                this.setState({ offset: 0, activePage: pageNumber })
                count = 0 * 10;
                this.props.listBookingFetch(count);
            }
        }
        else if (lastPage <= 1) {
            count = (pageNumber - 1) * 10;
            this.setState({ activePage: pageNumber, offset: count });
            this.props.listBookingFetch(count);
        }
    }

    handleCustomerChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSearch() {
        const { bookingFrom, bookingTo, travelFrom, travelTo, customer } = this.state;
        if (bookingFrom !== undefined && bookingTo !== undefined && travelFrom === undefined && travelTo === undefined && (customer !== undefined || customer !== "")) {
            const data = [{ bookingFrom: bookingFrom, bookingTo: bookingTo, travelFrom: 'undefined', travelTo: 'undefined', customer: customer }];
            localStorage.setItem('ListBookingSearch', JSON.stringify(data));
        }
        else if (bookingFrom !== undefined && bookingTo !== undefined && travelFrom === undefined && travelTo === undefined && (customer === undefined || customer === "")) {
            const data = [{ bookingFrom: bookingFrom, bookingTo: bookingTo, travelFrom: 'undefined', travelTo: 'undefined', customer: '' }];
            localStorage.setItem('ListBookingSearch', JSON.stringify(data));
        }
        else if (bookingFrom === undefined && bookingTo === undefined && travelFrom !== undefined && travelTo !== undefined && (customer !== undefined || customer !== "")) {
            const data = [{ bookingFrom: bookingFrom, bookingTo: 'undefined', travelFrom: travelFrom, travelTo: travelTo, customer: customer }];
            localStorage.setItem('ListBookingSearch', JSON.stringify(data));
        }
        else if (bookingFrom === undefined && bookingTo === undefined && travelFrom !== undefined && travelTo !== undefined && (customer === undefined || customer === "")) {
            const data = [{ bookingFrom: bookingFrom, bookingTo: 'undefined', travelFrom: travelFrom, travelTo: travelTo, customer: '' }];
            localStorage.setItem('ListBookingSearch', JSON.stringify(data));
        }
        else if (bookingFrom === undefined && bookingTo === undefined && travelFrom === undefined && travelTo === undefined && (customer !== undefined || customer !== "")) {
            const data = [{ bookingFrom: 'undefined', bookingTo: 'undefined', travelFrom: 'undefined', travelTo: 'undefined', customer: customer }];
            localStorage.setItem('ListBookingSearch', JSON.stringify(data));
        }
        else {
            const data = [{ bookingFrom: 'undefined', bookingTo: 'undefined', travelFrom: 'undefined', travelTo: 'undefined', customer: '' }];
            localStorage.setItem('ListBookingSearch', JSON.stringify(data));
            this.setState({ isValid: true })
        }
        this.props.searchListBooking(bookingFrom, bookingTo, travelFrom, travelTo, customer);
        this.setState({ activePage: 1, offset: 0 })
    }

    closeModal() {
        this.setState({ isValid: false })
    }

    render() {
        const { bookingFrom, bookingTo, travelFrom, travelTo, showBooking, showTravel, isValid } = this.state;
        const FORMAT = 'YYYY-MM-DD';
        const ListBookings = this.props.data ? this.props.data : [];
        const AlertMessage = this.props.messageAlert ? this.props.messageAlert : [];
        return (
            <div className="contents">
                <div className="row">
                    <div className="box-creditHeader">
                        <h6 className="text-header-booking"><FontAwesomeIcon className="text-pos-head-booking" icon={faClipboardList} />My bookings</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="boxSearchBooking">
                        <div className="headSearchBooking" style={{ backgroundColor: "#0083b0" }}>
                            <h6 className="text-head-bookings">Search Booking</h6>
                        </div>
                        <div className="containerBox">
                            {showBooking === true &&
                                <div className="boxline">
                                    <label>Booking Date To</label>
                                    <div className="input-group">
                                        <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                        <div className="input-group-area">
                                            <div className="InputFrom">
                                                <DayPickerInput
                                                    value={bookingFrom}
                                                    placeholder="From"
                                                    format={FORMAT}
                                                    formatDate={formatDate}
                                                    dayPickerProps={{
                                                        selectedDays: [bookingFrom, { from: bookingFrom, to: bookingTo }],
                                                        disabledDays: { after: bookingTo },
                                                        toMonth: bookingTo,
                                                        numberOfMonths: 1,
                                                        onDayClick: () => this.bookingTo.getInput().focus(),
                                                    }}
                                                    onDayChange={this.handleBookingFromChange}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {showBooking === true &&
                                <div className="boxline">
                                    <label>Booking Date To</label>
                                    <div className="input-group">
                                        <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                        <div className="input-group-area">
                                            <div className="InputFrom">
                                                <DayPickerInput
                                                    ref={el => (this.bookingTo = el)}
                                                    value={bookingTo}
                                                    placeholder="To"
                                                    format={FORMAT}
                                                    formatDate={formatDate}
                                                    dayPickerProps={{
                                                        selectedDays: [bookingFrom, { from: bookingFrom, to: bookingTo }],
                                                        disabledDays: { before: bookingFrom },
                                                        month: bookingFrom,
                                                        fromMonth: bookingFrom,
                                                        numberOfMonths: 1,
                                                    }}
                                                    onDayChange={this.handleBookingToChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {showTravel === true &&
                                <div className="boxline">
                                    <label>Travel Date To</label>
                                    <div className="input-group">
                                        <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                        <div className="input-group-area">
                                            <div className="InputFrom">
                                                <DayPickerInput
                                                    value={travelFrom}
                                                    placeholder="From"
                                                    format={FORMAT}
                                                    formatDate={formatDate}
                                                    dayPickerProps={{
                                                        selectedDays: [travelFrom, { from: travelFrom, to: travelTo }],
                                                        disabledDays: { after: travelTo },
                                                        toMonth: travelTo,
                                                        numberOfMonths: 1,
                                                        onDayClick: () => this.travelTo.getInput().focus(),
                                                    }}
                                                    onDayChange={this.handleTravelFromChange}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {showTravel === true &&
                                <div className="boxline">
                                    <label>Travel Date To</label>
                                    <div className="input-group">
                                        <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                        <div className="input-group-area">
                                            <div className="InputFrom">
                                                <DayPickerInput
                                                    ref={el => (this.travelTo = el)}
                                                    value={travelTo}
                                                    placeholder="To"
                                                    format={FORMAT}
                                                    formatDate={formatDate}
                                                    dayPickerProps={{
                                                        selectedDays: [travelFrom, { from: travelFrom, to: travelTo }],
                                                        disabledDays: { before: travelFrom },
                                                        month: travelFrom,
                                                        fromMonth: travelFrom,
                                                        numberOfMonths: 1,
                                                    }}
                                                    onDayChange={this.handleTravelToChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="boxline">
                                <label>Customer Name</label>
                                <div className="icon">
                                    <span className="icons-search"><FontAwesomeIcon icon={faUser} /></span>
                                    <input placeholder="Enter Customer Name" name="customer" value={this.state.customer} onChange={this.handleCustomerChange} />
                                </div>
                            </div>

                            <div className="boxline">
                                <div className="posButton">
                                    <button className="button" onClick={this.handleSearch}>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {this.props.pageTotal !== 0 &&
                        <div className="box-bookings-body">
                            <table>
                                <thead style={{ background: "#00b4db" }}>
                                    <tr>
                                        <th>Voucher ID</th>
                                        <th className="th-myBookingPassenger">Customer</th>
                                        <th className="th-myBookingRoute">Reference</th>
                                        <th className="th-myBookingTravel">Created At</th>
                                        <th className="th-myBookingPayment">Payment Status</th>
                                        <th className="th-myBookingBookingStatus">Booking Status</th>
                                        <th className="th-myBookingAdult">Adult</th>
                                        <th className="th-myBookingChild">Child</th>
                                        <th className="th-myBookingNetprice">Net Price</th>
                                        <th className="th-myBookingNull">{null}</th>
                                    </tr>
                                </thead>
                                <tbody className="tbody-mybooking">
                                    {ListBookings.map((e, index) => {
                                        return (
                                            <SubTableList key={index} List_Bookings={e} />
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="pagination">
                                {this.props.pageTotal &&
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={10}
                                        totalItemsCount={this.props.pageTotal}
                                        pageRangeDisplayed={5}
                                        innerClass="pagination"
                                        itemClass="page-item page-item-font"
                                        linkClass="page-link"
                                        prevPageText='prev'
                                        nextPageText='next'
                                        firstPageText='first'
                                        lastPageText='last'
                                        onChange={this.handlePageChange}
                                    />
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className="row">
                    <ModalAlert message={AlertMessage} action={isValid} onClick={this.closeModal} />
                </div>
                <Helmet>
                    <style>{`.InputFrom .DayPickerInput {width:100%};{color:white}`}</style>
                </Helmet>
            </div>
        );
    }
}
function mapStateToProps({ list_booking, create_booking }) {
    if (list_booking.status === 200000) {
        const data = list_booking.data;
        const messageAlert = list_booking.message;
        const pageTotal = list_booking.total;
        return { pageTotal, messageAlert, data, create_booking };
    }
    else if (list_booking.status === 400) {
        const data = list_booking.data.data;
        const messageAlert = list_booking.message;
        const pageTotal = list_booking.data.total;
        return { pageTotal, messageAlert, data, create_booking };
    }
    else {
        const data = list_booking.data;
        const messageAlert = list_booking.message;
        const pageTotal = list_booking.total;
        return { pageTotal, messageAlert, data, create_booking };
    }
}

export default connect(mapStateToProps, { listBookingFetch, searchListBooking })(ListBooking);