import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { history } from '../_helpers';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuth()
            ?  <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)

function isAuth() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) return false;
    let token = user.access_token;
    //todo call profile
    const pathUrl = process.env.REACT_APP_API_URL + "account/me";
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    return axios.get(pathUrl, { headers: headers })
        .then(res => {
            return res.status === "200" ? true : false;
        }).catch(err => {
            localStorage.clear();
            history.push("/");
            return false;
        });
}