import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import axios from 'axios';
import { USER_PROFILE_FETCH } from '../_types/types';

export const userActions = {
    login,
    logout,
    // register,
    getAll,
    // delete: _delete
    userProfile,
    refreshToken
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));
        userService.login(username, password).then(
            user => {
                dispatch(success(user));
                history.push("/booking/dashboard");
            },
            error => {
                localStorage.setItem('alert', JSON.stringify(error.response));
                dispatch(failure(error.response));
                dispatch(alertActions.error(error.response));
            }
        );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function userProfile() {
    const user = localStorage.getItem('user')
    const userData = JSON.parse(user);
    let token = userData.access_token;
    const pathUrl = process.env.REACT_APP_API_URL + `account/me`;
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };

    return dispatch => {
        axios.get(pathUrl, { headers: headers })
            .then(res => {
                dispatch({ type: USER_PROFILE_FETCH, payload: res.data })
            });
    }
}

function refreshToken() {
    console.log("refreshToken")
    const token = JSON.parse(localStorage.getItem('user'));
    const pathUrl = process.env.REACT_APP_API_URL + "auth/token/refresh";
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token.access_token}` };
    return dispatch => {
        axios.get(pathUrl, { headers: headers })
            .then(res => {
                console.log(res.data)
                localStorage.setItem('user', JSON.stringify(res.data));
                dispatch(success(res))
            });
    }

    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
}

// function register(user) {
//     return dispatch => {
//         dispatch(request(user));

//         userService.register(user)
//             .then(
//                 user => { 
//                     dispatch(success());
//                     history.push('/login');
//                     dispatch(alertActions.success('Registration successful'));
//                 },
//                 error => {
//                     dispatch(failure(error.toString()));
//                     dispatch(alertActions.error(error.toString()));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
//     function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
//     function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
// }

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}