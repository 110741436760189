import React, { Component } from 'react';

import NavBar from '../Parts/NavBar';
import SideDrawer from '../Parts/SideDrawer';
import POI from '../Components/POI';


class PoiStationView extends Component {
  render() {
    return (
      <div className="container-fluid">
        <NavBar />
        <SideDrawer />
        <POI />
      </div>
    );
  }
}

export default PoiStationView;
