import { LIST_BOOKING_FETCH, SEARCH_BOOKING_LIST, ALERT_SEARCHBOOKINGLIST } from '../_types/types';
import axios from 'axios';
import moment from 'moment';

// action pagination
export const listBookingFetch = (offset) => {
    const searchData = localStorage.getItem('ListBookingSearch');
    const dataSearch = JSON.parse(searchData);
    const user = localStorage.getItem('user');
    const userData = JSON.parse(user);
    let token = userData.access_token;
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    if (dataSearch[0].bookingFrom === "undefined" && dataSearch[0].bookingTo === "undefined" && dataSearch[0].travelFrom === "undefined"
        && dataSearch[0].travelTo === "undefined" && dataSearch[0].customer === "") {
        const pathUrl = process.env.REACT_APP_API_URL + `bookings/list?limit=10&offset=${offset}
        &travelDateFrom=&travelDateTo=&bookDateFrom=&bookDateTo=&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: LIST_BOOKING_FETCH, payload: res.data })
                });
        }
    }
    else if (dataSearch[0].bookingFrom !== "undefined" && dataSearch[0].bookingTo !== "undefined" && dataSearch[0].customer !== "") {
        const pathUrl = process.env.REACT_APP_API_URL + `bookings/list?limit=10&offset=${offset}
        &travelDateFrom=&travelDateTo=&bookDateFrom=${moment(dataSearch[0].bookingFrom).format('YYYY-MM-DD')}
        &bookDateTo=${moment(dataSearch[0].bookingTo).format('YYYY-MM-DD')}&customerName=${dataSearch[0].customer}`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: LIST_BOOKING_FETCH, payload: res.data })
                });
        }
    }
    else if (dataSearch[0].bookingFrom !== "undefined" && dataSearch[0].bookingTo !== "undefined" && dataSearch[0].customer === "") {
        const pathUrl = process.env.REACT_APP_API_URL + `bookings/list?limit=10&offset=${offset}
        &travelDateFrom=&travelDateTo=&bookDateFrom=${moment(dataSearch[0].bookingFrom).format('YYYY-MM-DD')}&bookDateTo=${moment(dataSearch[0].bookingTo).format('YYYY-MM-DD')}&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: LIST_BOOKING_FETCH, payload: res.data })
                });
        }
    }
    else if (dataSearch[0].travelFrom !== "undefined" && dataSearch[0].travelTo !== "undefined" && dataSearch[0].customer === "") {
        const pathUrl = process.env.REACT_APP_API_URL + `bookings/list?limit=10&offset=${offset}
        &travelDateFrom=${moment(dataSearch[0].travelFrom).format('YYYY-MM-DD')}&travelDateTo=${moment(dataSearch[0].travelTo).format('YYYY-MM-DD')}&bookDateFrom=&bookDateTo=&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: LIST_BOOKING_FETCH, payload: res.data })
                });
        }
    }
    else if (dataSearch[0].travelFrom !== "undefined" && dataSearch[0].travelTo !== "undefined" && dataSearch[0].customer !== "") {
        const pathUrl = process.env.REACT_APP_API_URL + `bookings/list?limit=10&offset=${offset}
        &travelDateFrom=${moment(dataSearch[0].travelFrom).format('YYYY-MM-DD')}&travelDateTo=${moment(dataSearch[0].travelTo).format('YYYY-MM-DD')}&bookDateFrom=&bookDateTo=&customerName=${dataSearch[0].customer}`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: LIST_BOOKING_FETCH, payload: res.data })
                });
        }
    }
    else {
        const pathUrl = process.env.REACT_APP_API_URL + `bookings/list?limit=10&offset=${offset}
        &travelDateFrom=&travelDateTo=&bookDateFrom=&bookDateTo=&customerName=${dataSearch[0].customer}`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: LIST_BOOKING_FETCH, payload: res.data })
                });
        }
    }
}

// action search List Bookings
export const searchListBooking = (bookingFrom, bookingTo, travelFrom, travelTo, customer) => {
    const user = localStorage.getItem('user');
    const userData = JSON.parse(user);
    let token = userData.access_token;
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    if (bookingFrom !== undefined && bookingTo !== undefined && customer !== undefined) {
        const pathUrl = process.env.REACT_APP_API_URL +
            `bookings/list?limit=10&offset=0&travelDateFrom=&travelDateTo=
             &bookDateFrom=${moment(bookingFrom).format('YYYY-MM-DD')}&bookDateTo=${moment(bookingTo).format('YYYY-MM-DD')}&customerName=${customer}`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: SEARCH_BOOKING_LIST, payload: res.data })
                });
        }
    }
    else if (bookingFrom !== undefined && bookingTo !== undefined && (customer === undefined || customer === "")) {
        const pathUrl = process.env.REACT_APP_API_URL +
            `bookings/list?limit=10&offset=0&travelDateFrom=&travelDateTo=
         &bookDateFrom=${moment(bookingFrom).format('YYYY-MM-DD')}&bookDateTo=${moment(bookingTo).format('YYYY-MM-DD')}&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: SEARCH_BOOKING_LIST, payload: res.data })
                });
        }
    }
    else if (bookingFrom !== undefined && bookingTo === undefined && ((customer === undefined || customer === "") || (customer !== undefined || customer !== ""))) {
        const pathUrl = process.env.REACT_APP_API_URL +
            `bookings/list?limit=10&offset=0&travelDateFrom=&travelDateTo=&bookDateFrom=&bookDateTo=&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: ALERT_SEARCHBOOKINGLIST, payload: { message: 'Please Select Booking To', status: 400, data: res.data } })
                });
        }
    }
    else if (bookingFrom === undefined && bookingTo !== undefined && ((customer === undefined || customer === "") || (customer !== undefined || customer !== ""))) {
        const pathUrl = process.env.REACT_APP_API_URL +
            `bookings/list?limit=10&offset=0&travelDateFrom=&travelDateTo=&bookDateFrom=&bookDateTo=&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: ALERT_SEARCHBOOKINGLIST, payload: { message: 'Please Select Booking From', status: 400, data: res.data } })
                });
        }
    }

    else if (travelFrom !== undefined && travelTo !== undefined && customer !== undefined) {
        const pathUrl = process.env.REACT_APP_API_URL +
            `bookings/list?limit=10&offset=0&travelDateFrom=${moment(travelFrom).format('YYYY-MM-DD')}&travelDateTo=${moment(travelTo).format('YYYY-MM-DD')}
            &bookDateFrom=&bookDateTo=&customerName=${customer}`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: SEARCH_BOOKING_LIST, payload: res.data })
                });
        }
    }
    else if (travelFrom !== undefined && travelTo !== undefined && customer === undefined) {
        const pathUrl = process.env.REACT_APP_API_URL +
            `bookings/list?limit=10&offset=0&travelDateFrom=${moment(travelFrom).format('YYYY-MM-DD')}&travelDateTo=${moment(travelTo).format('YYYY-MM-DD')}
            &bookDateFrom=&bookDateTo=&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: SEARCH_BOOKING_LIST, payload: res.data })
                });
        }
    }
    else if (travelFrom !== undefined && travelTo === undefined && ((customer === undefined || customer === "") || (customer !== undefined || customer !== ""))) {
        const pathUrl = process.env.REACT_APP_API_URL +
            `bookings/list?limit=10&offset=0&travelDateFrom=&travelDateTo=&bookDateFrom=&bookDateTo=&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: ALERT_SEARCHBOOKINGLIST, payload: { message: 'Please Select Travel To', status: 400, data: res.data } })
                });
        }
    }
    else if (travelFrom === undefined && travelTo !== undefined && ((customer === undefined || customer === "") || (customer !== undefined || customer !== ""))) {
        const pathUrl = process.env.REACT_APP_API_URL +
            `bookings/list?limit=10&offset=0&travelDateFrom=&travelDateTo=&bookDateFrom=&bookDateTo=&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: ALERT_SEARCHBOOKINGLIST, payload: { message: 'Please Select Travel from', status: 400, data: res.data } })
                });
        }
    }
    else if (bookingFrom === undefined && bookingTo === undefined && travelFrom === undefined && travelTo === undefined && (customer !== undefined || customer !== "")) {
        const pathUrl = process.env.REACT_APP_API_URL +
            `bookings/list?limit=10&offset=0&travelDateFrom=&travelDateTo=&bookDateFrom=&bookDateTo=&customerName=${customer}`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: SEARCH_BOOKING_LIST, payload: res.data })
                });
        }
    }
    else {
        const pathUrl = process.env.REACT_APP_API_URL + `bookings/list?limit=10&offset=0&travelDateFrom=&travelDateTo=&bookDateFrom=&bookDateTo=&customerName=`;
        return dispatch => {
            axios.get(pathUrl, { headers: headers })
                .then(res => {
                    dispatch({ type: ALERT_SEARCHBOOKINGLIST, payload: res.data })
                });
        }
    }

}