import React, { Component } from "react";
import { connect } from "react-redux";
import { productCreate, clearPopupErrorMessage } from "../../../../ManageRedux/_actions";
import { history } from '../../../../ManageRedux/_helpers';
import './style.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus, faShip, faTrain, faShuttleVan, faPlane } from '@fortawesome/free-solid-svg-icons';
import TitleName from '../TitleName';
import moment from 'moment';

class BookingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: '',
            email: '',
            phone: '',
            address: '',
            booking: '',
            ref: '',
            productInfo: [],
            submitted: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let data = localStorage.getItem('productInfo');
        this.setState({ productInfo: JSON.parse(data), });
        this.props.clearPopupErrorMessage();
    }

    componentDidUpdate() {
        const { message } = this.props.create_booking;
        if (message === "success") {
            history.push("/booking/me");
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const TitleName = this.props.TitleName;
        const { fname, lname, email, phone, address, booking, ref, productInfo } = this.state;
        if (fname && lname && email && phone && address && booking && ref && productInfo) {
            this.props.productCreate(TitleName, this.state)
        }
    }

    render() {
        const { product_ref, product_title, net_adult, net_child, adult, child, totalPrice, travelDate, product_category } = this.state.productInfo
        const { message, status } = this.props.create_booking;
        return (
            <div>
                <div className="nav-addcard">
                    <div className="boxHeader">
                        <Link to="/booking/create" className="link-addCard"> กลับไปค้นหาอีกครั้ง </Link>
                    </div>
                </div>

                <div className="boxContainer">
                    {status && message &&
                        <div className={message === "success" ? "boxAlert-success" : "boxAlert-danger"}>
                            <h6>{message}</h6>
                        </div>
                    }
                </div>

                <div className="boxContainer">
                    <span className="text-detailPay">Trip Itinerary & Payment</span>
                </div>

                <div className="boxContainer">
                    <div className="timeLine-wrapper">
                        <ul className="timeLine">
                            <li className="timeLine-title">
                                <div className="block-title">{moment(travelDate).format('DD/MM/YYYY')}</div>
                            </li>
                            <li className="timeLine-title">
                                <div className="timeLine-item-badge">
                                    <div className="timeLine-item-badge-icon">
                                        {product_category === "bus" && <FontAwesomeIcon title="Bus" className="badge-icon" icon={faBus} />}
                                        {product_category === "train" && <FontAwesomeIcon title="Train" className="badge-icon" icon={faTrain} />}
                                        {product_category === "boat" && <FontAwesomeIcon title="Ferry" className="badge-icon" icon={faShip} />}
                                        {product_category === "van" && <FontAwesomeIcon title="Ferry" className="badge-icon" icon={faShuttleVan} />}
                                        {product_category === "airline" && <FontAwesomeIcon title="Ferry" className="badge-icon" icon={faPlane} />}
                                    </div>
                                </div>
                                <div className="timeLine-item-panel">
                                    <table className="table-addCard">
                                        <thead className="thead-addCard">
                                            <tr>
                                                <th>Product Reference</th>
                                                <th>Product Title</th>
                                                <th>Adult</th>
                                                <th>Ahild</th>
                                                <th>Net Adult</th>
                                                <th>Net Child</th>
                                                <th>TotalPrice</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-addCard">{product_ref}</td>
                                                <td className="td-addCard">{product_title}</td>
                                                <td className="td-addCard">{adult}</td>
                                                <td className="td-addCard">{child}</td>
                                                <td className="td-addCard">{net_adult}</td>
                                                <td className="td-addCard">{net_child}</td>
                                                <td className="td-addCard">{totalPrice}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="boxContainer">
                    <h6 className="block-detail">Contact Details</h6>
                    <div className="detail-group">
                        <div className="detail-group-title">
                            <label className="label-title">Title Name</label>
                        </div>
                        <div className="detail-group-item">
                            <TitleName onChange={this.selectTitle} />
                        </div>
                    </div>
                    <div className="detail-group">
                        <div className="detail-group-title">
                            <label className="label-title">First Name</label>
                        </div>
                        <div className="detail-group-item">
                            <input className="input-cart" type="text" name="fname" value={this.state.fname} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="detail-group justify-content-end">
                        {this.state.submitted && this.state.fname === '' && <div className="text-alert">First Name is required</div>}
                    </div>
                    <div className="detail-group">
                        <div className="detail-group-title">
                            <label className="label-title">Last Name</label>
                        </div>
                        <div className="detail-group-item">
                            <input className="input-cart" type="text" name="lname" value={this.state.lname} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="detail-group justify-content-end">
                        {this.state.submitted && this.state.lname === '' && <div className="text-alert">Last Name is required</div>}
                    </div>
                    <div className="detail-group">
                        <div className="detail-group-title">
                            <label className="label-title">Email Address</label>
                        </div>
                        <div className="detail-group-item">
                            <input className="input-cart" type="email" name="email" value={this.state.email} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="detail-group justify-content-end">
                        {this.state.submitted && this.state.email === '' && <div className="text-alert">Email Address is required</div>}
                    </div>
                    <div className="detail-group">
                        <div className="detail-group-title">
                            <label className="label-title">Phone Number</label>
                        </div>
                        <div className="detail-group-item">
                            <input className="input-cart" type="text" name="phone" value={this.state.phone} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="detail-group justify-content-end">
                        {this.state.submitted && this.state.phone === '' && <div className="text-alert">Phone Number is required</div>}
                    </div>
                    <div className="detail-group">
                        <div className="detail-group-title">
                            <label className="label-title">Address</label>
                        </div>
                        <div className="detail-group-item">
                            <input className="input-cart" type="text" name="address" value={this.state.address} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="detail-group justify-content-end">
                        {this.state.submitted && this.state.address === '' && <div className="text-alert">Address is required</div>}
                    </div>
                    <div className="detail-group">
                        <div className="detail-group-title">
                            <label className="label-title">Booking By</label>
                        </div>
                        <div className="detail-group-item">
                            <input className="input-cart" type="text" name="booking" value={this.state.booking} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="detail-group justify-content-end">
                        {this.state.submitted && this.state.booking === '' && <div className="text-alert">Booking By is required</div>}
                    </div>
                    <div className="detail-group">
                        <div className="detail-group-title">
                            <label className="label-title">Your Ref Number</label>
                        </div>
                        <div className="detail-group-item">
                            <input className="input-cart" type="text" name="ref" value={this.state.ref} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="detail-group justify-content-end">
                        {this.state.submitted && this.state.ref === '' && <div className="text-alert">Your Reference Number is required</div>}
                    </div>
                    <div className="detail-group">
                        <div className="detail-group-button">
                            <button className="button pos-button" onClick={this.handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps({ productInfo, TitleName, create_booking }) {
    return { productInfo, TitleName, create_booking }
}

export default connect(mapStateToProps, { productCreate, clearPopupErrorMessage })(BookingForm);