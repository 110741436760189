import React, { Component } from 'react';
import './select_month.scss'

class DropdownMonth extends Component {

    static defaultProps = {
        list: []
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            labelItem: null,
            typeDropdown: null,
            data: props.data,
        };
    }

    componentWillMount() {
        const { label } = this.props.list ? this.props.list[0] : 0;
        let firstItem = null;
        if (typeof label != 'undefined') {
            this.checkType(false);
            firstItem = "Please select Months";
        } else {
            this.checkType(true);
            firstItem = this.props.list[0];
        }
        this.setState({
            labelItem: firstItem
        });
    }

    checkType = (value) => {
        this.setState({
            typeDropdown: value
        });
    };
    showDropdown = () => {
        this.setState({ isOpen: true });
        document.addEventListener("click", this.hideDropdown);
    };
    hideDropdown = () => {
        this.setState({ isOpen: false });
        document.removeEventListener("click", this.hideDropdown);
    };
    chooseItem = (value) => {
        if (this.state.labelItem !== value) {
            this.setState({
                labelItem: value,
                data: value
            })
        }
    };

    renderDataDropDown = (item, index) => {
        const { value, label } = this.state.typeDropdown ? { value: index, label: item } : item;
        return (
            <li
                key={index}
                value={value}
                onClick={() => this.chooseItem(label)}
            >
                <p>{label}</p>
            </li>
        );
    };
    render() {
        const { list } = this.props;
        return (
            <div className={`dropdown-month ${this.state.isOpen ? 'open' : ''}`}>
                <button className="dropdown-toggle" type="button" onClick={this.showDropdown}>
                    {this.state.labelItem}
                </button>
                <ul className="dropdown-menu">
                    {list.map(this.renderDataDropDown)}
                </ul>
            </div>
        );
    }
}

const monthLabel = [{ value: 1, label: 'January' },
{ value: 2, label: 'February' },
{ value: 3, label: 'March' },
{ value: 4, label: 'April' },
{ value: 5, label: 'May' },
{ value: 6, label: 'June' },
{ value: 7, label: 'July' },
{ value: 8, label: 'August' },
{ value: 9, label: 'September' },
{ value: 10, label: 'October' },
{ value: 11, label: 'November' },
{ value: 12, label: 'December' }];

class SelectMonth extends Component {
    render() {
        return (
            <ul className="selectMonth">
                <DropdownMonth list={monthLabel} />
            </ul>
        );
    }
}
export default SelectMonth;