import React, { Component } from 'react';

import NavBar from '../Parts/NavBar';
import SideDrawer from '../Parts/SideDrawer';
import Invoice from "../Components/Invoice";

class InvoiceView extends Component {
  render() {
    return (
      <div className="container-fluid">
        <NavBar />
        <SideDrawer />
        <Invoice />
      </div>
    );
  }
}

export default InvoiceView;
