import { BOOKING_CREATE, FAILED_BOOKING, CLEAR_POPUP_ERROR_MESSAGE } from '../_types/types'

export default function (state = [], action) {
    switch (action.type) {
        case BOOKING_CREATE:
            return action.payload;
        case FAILED_BOOKING:
            return action.payload;
        case CLEAR_POPUP_ERROR_MESSAGE:
            return action.payload;
        default:
            return state;
    }
}