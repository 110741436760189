import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../ManageRedux/_actions';
import DrawerToggle from '../DrawerToggle';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSortDown, faSortUp, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { history } from '../../../ManageRedux/_helpers';
import jwt from 'jwt-decode';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverLogout: false
        }
        this.logout = this.logout.bind(this);
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
    }

    componentDidMount() {
        this.props.userProfile();
        const token = JSON.parse(localStorage.getItem('user'));
        console.log('expired:', jwt(token.access_token).exp);
        console.log('date now:', Date.now() / 1000);
        let timeout = jwt(token.access_token).exp - Date.now() / 1000;
        console.log('TimeOut:', timeout);
        if (timeout <= 3700) {
            this.props.refreshToken();
        }
    }

    mouseEnter() {
        this.setState({ hoverLogout: true })
    }

    mouseLeave() {
        this.setState({ hoverLogout: false })
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        history.push('/login');
    }



    render() {
        const data = this.props.user_Profile && this.props.user_Profile.data ? this.props.user_Profile.data : [];
        return (
            <div className="toolbar">
                <div className="toolbar__toggle-button">
                    <DrawerToggle />
                </div>
                <div className="dropdown_logout" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                    <button className="logout_btn">
                        <FontAwesomeIcon className="icon_logout" icon={faUser} />
                        {data.name}
                        {this.state.hoverLogout === true ? <FontAwesomeIcon className="icon_logout_up" icon={faSortUp} />
                            : <FontAwesomeIcon className="icon_logout_down" icon={faSortDown} />}
                    </button>
                    <div className="dropdown-content">
                        <ul>
                            <li onClick={this.logout}><FontAwesomeIcon icon={faSignOutAlt} /> Log out</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps({ user_Profile }) {
    return { user_Profile };
}

const actionCreators = {
    userProfile: userActions.userProfile,
    refreshToken: userActions.refreshToken
}

export default connect(mapStateToProps, actionCreators)(NavBar);
