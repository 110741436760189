import { PRODUCTLISTS_FETCH } from '../_types/types';
import axios from 'axios';

export const productListFetch = (offset, keywords) => {
    const user = localStorage.getItem('user')
    const userData = JSON.parse(user);
    let token = userData.access_token;
    const pathUrl = process.env.REACT_APP_API_URL + `products/list?limit=10&offset=${offset}&keywords=${keywords}`;
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };

    return dispatch => {
        axios.get(pathUrl, { headers: headers })
            .then(res => {
                dispatch({ type: PRODUCTLISTS_FETCH, payload: res.data })
            });
    }
}