import React, { Component } from 'react';
import loading from '../../../../Css/Logo/loading2.gif';
import axios from 'axios';
import { history } from '../../../../ManageRedux/_helpers/history';

class FormLoad extends Component {

    componentDidMount() {
        const query = window.location.search;
        const user = JSON.parse(localStorage.getItem('user'));
        let token = user.access_token;
        const pathUrl = process.env.REACT_APP_API_URL + "payment/verify" + query;
        const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
        return axios.get(pathUrl, headers).then
            (res => {
                localStorage.setItem('alert', JSON.stringify(res.data))
                history.push('/booking/dashboard');
            }).catch((error => {
                localStorage.setItem('alert', JSON.stringify(error.response))
                history.push('/booking/dashboard');
            }))
    }

    render() {
        return (
            <div>
                <img src={loading} alt="loading_icon" className="img_loading2" />
            </div>
        );
    }
}
export default FormLoad;