export const PRODUCTLISTS_FETCH = "productList_fetch";

export const PRODUCTINFO_FETCH = "productInfo_fetch";

export const BOOKING_CREATE = "productInfo_save";

export const FAILED_BOOKING = "failed_booking";

export const SELECT_TITLE_NAME = "selectTitleName";

export const CLEAR_POPUP_ERROR_MESSAGE = "clearPopupErrorMessage";

export const LIST_BOOKING_FETCH = "listBooking";

export const SEARCH_BOOKING_LIST = "searchBookingList";

export const ALERT_SEARCHBOOKINGLIST = "alertSearchBookingList"

export const USER_PROFILE_FETCH = "userProfile_fetch";

export const REFRESH_TOKEN = "refresh_token";
