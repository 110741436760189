import React, { Component } from 'react';

import NavBar from '../Parts/NavBar';
import SideDrawer from '../Parts/SideDrawer';
import ListBooking from "../Components/ListBooking";

class MyBookingView extends Component {
    render() {
        return (
            <div className="container-fluid">
                <NavBar />
                <SideDrawer />
                <ListBooking />
            </div>
        );
    }
}

export default MyBookingView;