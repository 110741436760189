import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faPrint, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import './style.css';

import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        };
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <div className="contents">
                <Container fluid>
                    <div className="row">
                        <div className="column">

                            <div className="box-calendar-head1">
                                <h6 className="text-header-calendar"><FontAwesomeIcon className="text-pos-head-icon-calendar" icon={faCalendarAlt} />Calendar of bookings</h6>
                                <button className="button-darkblue">Today</button>
                                <button className="button-default-calendar">Tomorrow</button>
                                <div className="icon-date">
                                    <span className="icons-date"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                    <div className={this.state.width <= 480 ? "InputDate-calendar" : "datePicker_fontSize"}>
                                        <DayPickerInput />
                                    </div>
                                </div>
                            </div>
                            <div className="box-calendar-head2">
                                <button className="button-lightblue"><FontAwesomeIcon className="button-icon" icon={faFileExport} />Export all</button>
                                <button className="button-lightblue"><FontAwesomeIcon className="button-icon" icon={faPrint} />Print all</button>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="column">
                            <div className="box-calendar">
                                <div className="box-card-head" style={{ backgroundColor: "#00b4db" }}>
                                    <label className="text-card-head">Bangkok (Khaosan Road)<FontAwesomeIcon className="text-icon-arrow" icon={faLongArrowAltRight} />Koh Tao (Maehaad Pier)</label>
                                </div>
                                <div className="containerBox">
                                    <table className="table-calendar">
                                        <thead className="thead-calendar">
                                            <tr>
                                                <th className="th-calendarVoucher">Voucher</th>
                                                <th className="th-calendarPassengers">Passengers</th>
                                                <th className="th-calendarRoute">Route</th>
                                                <th className="th-calendarTraveldate">Travel Date</th>
                                                <th className="th-calendarDeparture">Departure</th>
                                                <th className="th-calendarArrival">Arrival</th>
                                                <th className="th-calendarNetPrice">Net price</th>
                                                <th className="th-calendarComments">Comments</th>
                                                <th className="th-calendarEmpty"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-calendar">
                                                    <FontAwesomeIcon className="text-icon-calendar" icon={faFileExport} />R90001230
                                                </td>
                                                <td className="td-calendar">
                                                    <ol className="ol-calendar">
                                                        <li>1. Max Musterman</li>
                                                        <li>2. Maxine Musterman</li>
                                                    </ol>
                                                </td>
                                                <td className="td-calendar">
                                                    <ol className="ol-calendar">
                                                        <li>Bangkok (Khaosan Road)</li>
                                                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /></li>
                                                        <li>Koh Tao (Maehaad Pier)</li>
                                                    </ol>
                                                </td>
                                                <td className="td-calendar">30-Sep-19</td>
                                                <td className="td-calendar">20:00</td>
                                                <td className="td-calendar">10:00 + 1</td>
                                                <td className="td-calendar">1,600 THB</td>
                                                <td className="td-calendar">test</td>
                                                <td className="td-calendar">
                                                    <button className="button-default-calendar button-margin"><FontAwesomeIcon className="button-icon" icon={faFileExport} />Export</button>
                                                    <button className="button-default-calendar button-margin"><FontAwesomeIcon className="button-icon" icon={faPrint} />Print</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column">
                            <div className="box-calendar">
                                <div className="box-card-head" style={{ backgroundColor: "#00b4db" }}>
                                    <label className="text-card-head">Bangkok (Khaosan Road)<FontAwesomeIcon className="text-icon-arrow" icon={faLongArrowAltRight} />Koh Tao (Maehaad Pier)</label>
                                </div>
                                <div className="containerBox">
                                    <table className="table-calendar">
                                        <thead className="thead-calendar">
                                            <tr>
                                                <th className="th-calendarVoucher">Voucher</th>
                                                <th className="th-calendarPassengers">Passengers</th>
                                                <th className="th-calendarRoute">Route</th>
                                                <th className="th-calendarTraveldate">Travel Date</th>
                                                <th className="th-calendarDeparture">Departure</th>
                                                <th className="th-calendarArrival">Arrival</th>
                                                <th className="th-calendarNetPrice">Net price</th>
                                                <th className="th-calendarComments">Comments</th>
                                                <th className="th-calendarEmpty"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-calendar">
                                                    <FontAwesomeIcon className="text-icon-calendar" icon={faFileExport} />R90001230
                                                </td>
                                                <td className="td-calendar">
                                                    <ol className="ol-calendar">
                                                        <li>1. Max Musterman</li>
                                                        <li>2. Maxine Musterman</li>
                                                    </ol>
                                                </td>
                                                <td className="td-calendar">
                                                    <ol className="ol-calendar">
                                                        <li>Bangkok (Khaosan Road)</li>
                                                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /></li>
                                                        <li>Koh Tao (Maehaad Pier)</li>
                                                    </ol>
                                                </td>
                                                <td className="td-calendar">30-Sep-19</td>
                                                <td className="td-calendar">20:00</td>
                                                <td className="td-calendar">10:00 + 1</td>
                                                <td className="td-calendar">1,600 THB</td>
                                                <td className="td-calendar">test</td>
                                                <td className="td-calendar">
                                                    <button className="button-default-calendar button-margin"><FontAwesomeIcon className="button-icon" icon={faFileExport} />Export</button>
                                                    <button className="button-default-calendar button-margin"><FontAwesomeIcon className="button-icon" icon={faPrint} />Print</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column">
                            <div className="box-calendar">
                                <div className="box-card-head" style={{ backgroundColor: "#00b4db" }}>
                                    <label className="text-card-head">Bangkok (Khaosan Road)<FontAwesomeIcon className="text-icon-arrow" icon={faLongArrowAltRight} />Koh Tao (Maehaad Pier)</label>
                                </div>
                                <div className="containerBox">
                                    <table className="table-calendar">
                                        <thead className="thead-calendar">
                                            <tr>
                                                <th className="th-calendarVoucher">Voucher</th>
                                                <th className="th-calendarPassengers">Passengers</th>
                                                <th className="th-calendarRoute">Route</th>
                                                <th className="th-calendarTraveldate">Travel Date</th>
                                                <th className="th-calendarDeparture">Departure</th>
                                                <th className="th-calendarArrival">Arrival</th>
                                                <th className="th-calendarNetPrice">Net price</th>
                                                <th className="th-calendarComments">Comments</th>
                                                <th className="th-calendarEmpty"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-calendar">
                                                    <FontAwesomeIcon className="text-icon-calendar" icon={faFileExport} />R90001230
                                                </td>
                                                <td className="td-calendar">
                                                    <ol className="ol-calendar">
                                                        <li>1. Max Musterman</li>
                                                        <li>2. Maxine Musterman</li>
                                                    </ol>
                                                </td>
                                                <td className="td-calendar">
                                                    <ol className="ol-calendar">
                                                        <li>Bangkok (Khaosan Road)</li>
                                                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /></li>
                                                        <li>Koh Tao (Maehaad Pier)</li>
                                                    </ol>
                                                </td>
                                                <td className="td-calendar">30-Sep-19</td>
                                                <td className="td-calendar">20:00</td>
                                                <td className="td-calendar">10:00 + 1</td>
                                                <td className="td-calendar">1,600 THB</td>
                                                <td className="td-calendar">test</td>
                                                <td className="td-calendar">
                                                    <button className="button-default-calendar button-margin"><FontAwesomeIcon className="button-icon" icon={faFileExport} />Export</button>
                                                    <button className="button-default-calendar button-margin"><FontAwesomeIcon className="button-icon" icon={faPrint} />Print</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column">
                            <div className="box-calendar">
                                <div className="box-card-head" style={{ backgroundColor: "#00b4db" }}>
                                    <label className="text-card-head">Bangkok (Khaosan Road)<FontAwesomeIcon className="text-icon-arrow" icon={faLongArrowAltRight} />Koh Tao (Maehaad Pier)</label>
                                </div>
                                <div className="containerBox">
                                    <table className="table-calendar">
                                        <thead className="thead-calendar">
                                            <tr>
                                                <th className="th-calendarVoucher">Voucher</th>
                                                <th className="th-calendarPassengers">Passengers</th>
                                                <th className="th-calendarRoute">Route</th>
                                                <th className="th-calendarTraveldate">Travel Date</th>
                                                <th className="th-calendarDeparture">Departure</th>
                                                <th className="th-calendarArrival">Arrival</th>
                                                <th className="th-calendarNetPrice">Net price</th>
                                                <th className="th-calendarComments">Comments</th>
                                                <th className="th-calendarEmpty"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-calendar">
                                                    <FontAwesomeIcon className="text-icon-calendar" icon={faFileExport} />R90001230
                                                </td>
                                                <td className="td-calendar">
                                                    <ol className="ol-calendar">
                                                        <li>1. Max Musterman</li>
                                                        <li>2. Maxine Musterman</li>
                                                    </ol>
                                                </td>
                                                <td className="td-calendar">
                                                    <ol className="ol-calendar">
                                                        <li>Bangkok (Khaosan Road)</li>
                                                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /></li>
                                                        <li>Koh Tao (Maehaad Pier)</li>
                                                    </ol>
                                                </td>
                                                <td className="td-calendar">30-Sep-19</td>
                                                <td className="td-calendar">20:00</td>
                                                <td className="td-calendar">10:00 + 1</td>
                                                <td className="td-calendar">1,600 THB</td>
                                                <td className="td-calendar">test</td>
                                                <td className="td-calendar">
                                                    <button className="button-default-calendar button-margin"><FontAwesomeIcon className="button-icon" icon={faFileExport} />Export</button>
                                                    <button className="button-default-calendar button-margin"><FontAwesomeIcon className="button-icon" icon={faPrint} />Print</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Helmet>
                    <style>{`.InputDate-calendar .DayPickerInput-Overlay {margin-left: -85px;}`}</style>
                </Helmet>
            </div>
        );
    }
}
export default Calendar;