import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { App } from './App';
import { store } from './ManageRedux/_helpers';

// store.subscribe(() => {
//     console.log("update store:",store.getState());
// })

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);