import React, { Component } from 'react';
import BookingForm from '../Components/CreateBooking/BookingForm';

class AddCart extends Component {
    render() {
        return (
            <div>
                <BookingForm />
            </div>
        );
    }
}

export default AddCart;