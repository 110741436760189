import { LIST_BOOKING_FETCH, SEARCH_BOOKING_LIST, ALERT_SEARCHBOOKINGLIST } from '../_types/types'

export default function (state = [], action) {
    switch (action.type) {
        case LIST_BOOKING_FETCH:
            return action.payload;
        case SEARCH_BOOKING_LIST:
            return action.payload;
        case ALERT_SEARCHBOOKINGLIST:
            return action.payload;
        default:
            return state;
    }
}