import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoll, faFilePdf, faPrint } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import './sales.css';
import SelectMonth from './select_month';
import moment from 'moment';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Helmet from 'react-helmet';
import Chart from './chart';

class SalesReports extends Component {
    constructor(props) {
        super(props);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.state = {
            from: undefined,
            to: undefined,
            width: window.innerWidth,
        };
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }

    handleFromChange(from) {
        // Change the from date and focus the "to" input field
        this.setState({ from });
    }

    handleToChange(to) {
        this.setState({ to }, this.showFromMonth);
    }
    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        const FORMAT = 'YYYY/MM/DD';
        return (
            <div className="contents">
                <Container fluid>
                    <div className="row">
                        <div className="column">
                            <h6 className="text-sales-head0"><FontAwesomeIcon icon={faPoll} /> Sales report</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column">
                            <div className="box-sales-head1">
                                <div className="box-sales-view">
                                    <label className="text-sales-head1">View</label>
                                    <SelectMonth />
                                </div>
                            </div>
                            <div className="box-sales-head2">
                                <div className="box-sales-date1">
                                    <h6 className="text-sales-head2">From</h6>
                                    <div className="sales-input-group">
                                        <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                        <div className="input-group-area">
                                            <div className="InputFrom">
                                                <DayPickerInput
                                                    value={from}
                                                    // iconPosition={'left'} icon={{ faCalendarAlt }}
                                                    placeholder="yyyy-mm-dd"
                                                    format={FORMAT}
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                    dayPickerProps={{
                                                        selectedDays: [from, { from, to }],
                                                        disabledDays: { after: to },
                                                        toMonth: to,
                                                        modifiers,
                                                        numberOfMonths: 1,
                                                        onDayClick: () => this.to.getInput().focus(),
                                                    }}
                                                    onDayChange={this.handleFromChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-sales-date2">
                                    <h6 className="text-sales-head2">To</h6>
                                    <div className="sales-input-group">
                                        <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                        <div className="input-group-area">
                                            <div className={this.state.width <= 480 ? "InputFrom InputTo" : "InputFrom"}>
                                                <DayPickerInput
                                                    ref={el => (this.to = el)}
                                                    value={to}
                                                    placeholder="yyyy-mm-dd"
                                                    format={FORMAT}
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                    dayPickerProps={{
                                                        selectedDays: [from, { from, to }],
                                                        disabledDays: { before: from },
                                                        modifiers,
                                                        month: from,
                                                        fromMonth: from,
                                                        numberOfMonths: 1,
                                                    }}
                                                    onDayChange={this.handleToChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-sales-button">
                                    <button className="button-sales">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column">
                            <div className="box-sales-report">
                                <div className="box-sale-report-header" style={{ backgroundColor: "#00b4db" }}>
                                    <h6 className="text-sales-head3">Data as of April 01,2019 untill October 30,2019</h6>
                                </div>
                                <div className="box-sale-report-body">
                                    <div className="box-sales-button-pdf_print">
                                        <div className="box-sales-print"><button className="button-sales-print"><FontAwesomeIcon className="sales-icon-print" icon={faPrint} /></button></div>
                                        <div className="box-sales-pdf"><button className="button-sales-pdf"><FontAwesomeIcon className="sales-icon-pdf" icon={faFilePdf} /></button></div>
                                    </div>
                                    <div className="box-sales-chart">
                                        {/* {this.state.width <= 992 ? <Chart width={600} height={700} /> : <Chart width={1550} height={600} />} */}
                                        <Chart />
                                    </div>
                                    <div className="box-sales-table1">
                                        <table>
                                            <tbody>
                                                <tr className="tr-sales">
                                                    <td>Booking</td>
                                                    <td>42</td>
                                                    <td>152</td>
                                                    <td>113</td>
                                                    <td>268</td>
                                                    <td>230</td>
                                                    <td>114</td>
                                                </tr>
                                                <tr className="tr-sales">
                                                    <td>Sales</td>
                                                    <td>9,600THB</td>
                                                    <td>30,400THB</td>
                                                    <td>22,800THB</td>
                                                    <td>57,000THB</td>
                                                    <td>46,000THB</td>
                                                    <td>28,000THB</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <h6 className="text-sales-head4">Data Broken Down by Route</h6>
                                    <div className="box-sales-table2">
                                        <table>
                                            <thead className="thead-sales">
                                                <tr>
                                                    <th className="th-sales">Route</th>
                                                    <th className="th-sales">Bookings</th>
                                                    <th className="th-sales">Sales</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tbody-sales">
                                                <tr>
                                                    <td className="td-sales">Total</td>
                                                    <td className="td-sales">736</td>
                                                    <td className="td-sales">195,000 THB</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-sales">Bangkok (Khaosan Rd.) - Koh Tao</td>
                                                    <td className="td-sales">35</td>
                                                    <td className="td-sales">28,000 THB</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-sales">Koh Tao - Koh Phangan</td>
                                                    <td className="td-sales">28</td>
                                                    <td className="td-sales">8,400 THB</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-sales">Koh Phangan - Koh Tao</td>
                                                    <td className="td-sales">26</td>
                                                    <td className="td-sales">7,800 THB</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Helmet>
                    <style>{`.InputFrom .DayPickerInput {width:100%};{color:white}
                    .InputTo .DayPickerInput-Overlay{margin-left: -110px}`}</style>
                </Helmet>
            </div >
        );
    }
}
export default SalesReports;