import React, { Component } from "react";
import "./Css/login/cssLogin1.css";
import "./Css/login/cssLogin2.css";
import "./Css/login/ck_radio.css";
import logo from "./Css/Logo/logo.png";
import loading from "./Css/Logo/loading.gif";
import { connect } from 'react-redux';
import { userActions } from './ManageRedux/_actions';
import ModalAlert from './View/Components/CreateBooking/ModalAlert';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            message: '',
            isValid: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentWillReceiveProps() {
        if (localStorage.getItem('alert')) {
            const error = JSON.parse(localStorage.getItem('alert'));
            if (error.status === 500) {
                this.setState({ message: 'Internal Server Error', isValid: true })
            }
            else if (error.status === 401) {
                this.setState({ message: 'Unauthorized', isValid: true })
            }
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);

        }
    }

    closeModal() {
        this.setState({ isValid: false });
        localStorage.removeItem('alert');
    }

    render() {
        const { loggingIn } = this.props;
        const { isValid, message } = this.state;
        return (
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100 p-t-25 p-b-50">
                        <span className="login100-form-title p-b-15">
                            <img src={logo} alt="logo_image" />
                        </span>
                        <form onSubmit={this.handleSubmit}>
                            <div className="login100-form p-b-30 p-t-5">
                                <div className="text-uppercase p-t-40 p-l-70">
                                    <p className="text-login">Username</p>
                                </div>

                                <div className="wrap-input100">
                                    <input className="input-control" type="email" name="username" value={this.state.username} onChange={this.handleChange} />
                                </div>

                                {this.state.submitted && !this.state.username &&
                                    <div className="alert-validate">Username is required</div>
                                }

                                <div className="text-uppercase p-t-5 p-l-70">
                                    <p className="text-login">Password</p>
                                </div>

                                <div className="wrap-input100" >
                                    <input className="input-control" type="password" name="password" value={this.state.password} onChange={this.handleChange} />
                                </div>

                                {this.state.submitted && !this.state.password &&
                                    <div className="alert-validate">Password is required</div>
                                }

                                <div className="wrap-input50 p-l-70 p-t-5">
                                    <input type="checkbox" id="checkbox" className="checkmark" />
                                    <p className="text-login tag">Remember Me</p>
                                </div>

                                <div className="container-login100-form-btn m-t-20">
                                    {loggingIn &&
                                        <img src={loading} alt="loading_icon" className="img_load" />
                                    }
                                    <button className="login100-form-btn">Log In</button>
                                </div>

                                <div className="text-uppercase p-t-15 p-l-70">
                                    <a className="link-control1" href="url"> Lost Your PassWord?</a>
                                </div>
                            </div>
                        </form>
                        <div className="text-uppercase p-t-15">
                            <a className="link-control2" href="url">Don't Have An Account Yet?</a>
                        </div>
                    </div>
                </div>
                <div>
                    <ModalAlert message={message} action={isValid} onClick={this.closeModal} />
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};
const connectedLogin = connect(mapState, actionCreators)(Login);
export { connectedLogin as Login };
