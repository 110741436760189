import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faMapMarkedAlt, faEnvelope, faFilePdf } from '@fortawesome/free-solid-svg-icons';

class SubTableList extends Component {
    render() {
        const { code, title_name, first_name, last_name, reference, created_at, status, payment_status, total } = this.props.List_Bookings;
        return (
            <tr>
                <td className="table-text-center">{code}</td>
                <td>
                    <ul>
                        <li>{title_name} {first_name} {last_name}</li>
                    </ul>
                </td>
                <td>
                    <label>{reference}</label>
                </td>
                <td className="table-text-center">{moment(created_at).format('YYYY-MM-DD')}</td>
                <td className="table-text-center">
                    {payment_status === "paid" &&
                        <button className="button-Success">PAID</button>
                    }
                    {payment_status === "waiting" &&
                        <button className="button-Warning">PENDING</button>
                    }
                    {payment_status === "refund" &&
                        <button className="button-Danger">FAILED</button>
                    }
                </td>
                <td className="table-text-center">
                    {status === "complete" &&
                        <button className="button-Success">CONFIRMED</button>
                    }
                    {status === "waiting" &&
                        <button className="button-Warning">WAITING</button>
                    }
                    {status === "cancel" &&
                        <button className="button-Danger">CANCELLED</button>
                    }
                </td>
                <td>2</td>
                <td>0</td>
                <td>{total}</td>
                <td className="table-text-center">
                    {/* <button className="button-default"><FontAwesomeIcon icon={faMapMarkedAlt} /> MAP</button>
                    <button className="button-default"><FontAwesomeIcon icon={faFilePdf} /> PDF</button>
                    <button className="button-default"><FontAwesomeIcon icon={faEnvelope} /> SEND</button> */}
                </td>
            </tr>
        );
    }
}
export default SubTableList;