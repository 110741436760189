import { PRODUCTLISTS_FETCH } from '../_types/types'

export default function (state = [], action) {
    switch (action.type) {
        case PRODUCTLISTS_FETCH:
            localStorage.removeItem('loading');
            return action.payload;
        default:
            return state;
    }
}