import React, { Component } from 'react';
import NavBar from '../Parts/NavBar';
import SideDrawer from '../Parts/SideDrawer';
import SalesReports from '../Components/SalesReport/SalesReport';

class SalesReportView extends Component {
    render() {
        return (
            <div className="container">
                <NavBar />
                <SideDrawer />
                <SalesReports />
            </div>
        );
    }
}

export default SalesReportView;