import React, { Component } from 'react';
import './style.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';

class DropDown extends Component {

    static defaultProps = {
        list: []
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            labelItem: null,
            typeDropdown: null,
            data: props.data,
        };
    }

    componentWillMount() {
        const { label } = this.props.list ? this.props.list[0] : 0;
        let firstItem = null;
        if (typeof label != 'undefined') {
            this.checkType(false);
            firstItem = "Please select departure";
        } else {
            this.checkType(true);
            firstItem = this.props.list[0];
        }
        this.setState({
            labelItem: firstItem
        });
    }

    checkType = (value) => {
        this.setState({
            typeDropdown: value
        });
    };
    showDropdown = () => {
        this.setState({ isOpen: true });
        document.addEventListener("click", this.hideDropdown);
    };
    hideDropdown = () => {
        this.setState({ isOpen: false });
        document.removeEventListener("click", this.hideDropdown);
    };
    chooseItem = (value) => {
        if (this.state.labelItem !== value) {
            this.setState({
                labelItem: value,
                data: value
            })
        }
    };

    renderDataDropDown = (item, index) => {
        const { value, label } = this.state.typeDropdown ? { value: index, label: item } : item;
        return (
            <li
                key={index}
                value={value}
                onClick={() => this.chooseItem(label)}
            >
                <p>{label}</p>
            </li>
        );
    };
    render() {
        const { list } = this.props;
        return (
            <div className={`dropdown-poi ${this.state.isOpen ? 'open' : ''}`}>
                <button className="dropdown-toggle" type="button" onClick={this.showDropdown}>
                    {this.state.labelItem}
                </button>
                <ul className="dropdown-menu">
                    {list.map(this.renderDataDropDown)}
                </ul>
            </div>
        );
    }
}

const Departure = [{ value: 1, label: 'Khaosan Road' },
{ value: 2, label: 'Matapon Pier' },
{ value: 3, label: 'Maehhad Pier' },
{ value: 4, label: 'Tongsala Pier' },
{ value: 5, label: 'Nathon Pier' },
{ value: 6, label: 'Donsak Pier' },
{ value: 7, label: 'Suratthani Town' },
{ value: 8, label: 'Suratthani Train' },
{ value: 9, label: 'Andaman Wave Office' }];

class DepartureSelect extends Component {
    render() {
        return (
            <ul className="selectPoi">
                <FontAwesomeIcon className="icon-poi" icon={faSearchLocation} /><DropDown list={Departure} />
            </ul>
        );
    }
}
export default DepartureSelect;