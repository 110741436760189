import React, { Component } from 'react';
import { connect } from 'react-redux';
import { productInfoFetch } from "../../../ManageRedux/_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import PickUpDropOff from './PickUp_DropOff';
import moment from 'moment';
import axios from 'axios';
import { history } from '../../../ManageRedux/_helpers';
import ModalAlert from './ModalAlert';

class SubTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adult: 0,
            child: 0,
            totalAdult: 0,
            totalChild: 0,
            totalPrice: 0,
            submitted: false,
            selectedDay: moment(new Date()).format('YYYY-MM-DD'),
            isEmpty: true,
            isDisabled: false,
            netAdult: 0,
            netChild: 0,
            isValid: false,
            message: "",
            checkAllotment: 0,
        };
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleAddToCard = this.handleAddToCard.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.addAdult = this.addAdult.bind(this);
        this.subtractAdult = this.subtractAdult.bind(this);
        this.addChild = this.addChild.bind(this);
        this.subtractChild = this.subtractChild.bind(this);
    }

    fetchAllotment(selectedDay) {
        const { product_id, product_ref, product_category } = this.props.data;
        const user = localStorage.getItem('user')
        const userData = JSON.parse(user);
        let token = userData.access_token;
        const pathUrl = process.env.REACT_APP_API_URL + `products/allotment`;
        const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
        const body = {
            "date": selectedDay,
            "product_no": product_id,
            "category": product_category,
            "reference": product_ref
        };
        return axios.post(pathUrl, body, { headers: headers })
            .then(res => {
                this.setState({
                    checkAllotment: 200,
                    netAdult: res.data.data.net_adult,
                    netChild: res.data.data.net_child,
                    adult: 0,
                    child: 0,
                    totalAdult: 0,
                    totalChild: 0,
                    totalPrice: 0,
                })
            })
            .catch(error => {
                this.setState({
                    checkAllotment: 400,
                    netAdult: 0,
                    netChild: 0,
                    adult: 0,
                    child: 0,
                    totalAdult: 0,
                    totalChild: 0,
                    totalPrice: 0,
                })
            });
    }

    componentDidMount() {
        const { data } = this.props
        this.setState({
            netAdult: data.net_adult,
            netChild: data.net_child,
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.activePage !== this.props.activePage) {
            //Perform some operation
            this.setState({
                adult: 0,
                child: 0,
                totalAdult: 0,
                totalChild: 0,
                totalPrice: 0,
            });
        }
    }

    addAdult(price) {
        const { adult, totalChild, checkAllotment } = this.state;
        let total = 0;
        let sumPriceAdult = 0;
        if (adult !== 0 && totalChild === 0) {
            let newAdult = adult + 1;
            total = newAdult * price;
            this.setState({ totalAdult: total, adult: newAdult, totalPrice: total });
        }
        else if (adult !== 0 && totalChild !== 0) {
            let newAdult = adult + 1;
            sumPriceAdult = newAdult * price
            total = (newAdult * price) + totalChild;

            this.setState({ totalAdult: sumPriceAdult, adult: newAdult, totalPrice: total });
        }
        else if (adult === 0 && totalChild !== 0) {
            let newAdult = 1;
            sumPriceAdult = newAdult * price
            total = (newAdult * price) + totalChild;
            this.setState({ totalAdult: sumPriceAdult, adult: newAdult, totalPrice: total });
        }
        else if (price === 0 && checkAllotment === 400) {
            this.setState({ totalAdult: 0, adult: 0, totalPrice: 0 });
        }
        else {
            let newAdult = 1;
            total = newAdult * price;
            this.setState({ totalAdult: total, adult: newAdult, totalPrice: total });
        }
    }

    subtractAdult(price) {
        let { adult, child, totalChild, totalPrice } = this.state;
        let sumPriceAdult = 0
        let countAdult = adult - 1;
        if (countAdult === 0 && child === 0) {
            this.setState({ adult: 0, totalAdult: 0, totalPrice: 0 })
        }
        else if (countAdult > 0 && child === 0) {
            let total = countAdult * price;
            this.setState({ totalAdult: total, adult: countAdult, totalPrice: total })
        }
        else if (countAdult > 0 && child !== 0) {
            sumPriceAdult = countAdult * price;
            let total = (countAdult * price) + totalChild;
            this.setState({ totalAdult: sumPriceAdult, adult: countAdult, totalPrice: total })
        }
        else if (countAdult === 0 && child !== 0) {
            sumPriceAdult = 0 * price;
            let total = sumPriceAdult + totalChild;
            this.setState({ totalAdult: 0, adult: 0, totalPrice: total })
        }
        else if (countAdult < 0) {
            this.setState({ totalAdult: 0, adult: 0, totalPrice: totalPrice })
        }
    }

    addChild(price) {
        const { child, totalAdult, checkAllotment } = this.state;
        let total = 0;
        let sumPriceChild = 0;
        if (child !== 0 && totalAdult === 0) {
            let newChild = child + 1;
            total = newChild * price;
            this.setState({ totalChild: total, child: newChild, totalPrice: total });
        }
        else if (child !== 0 && totalAdult !== 0) {
            let newChild = child + 1;
            sumPriceChild = newChild * price
            total = (newChild * price) + totalAdult;

            this.setState({ totalChild: sumPriceChild, child: newChild, totalPrice: total });
        }
        else if (child === 0 && totalAdult !== 0) {
            let newChild = 1;
            sumPriceChild = newChild * price
            total = (newChild * price) + totalAdult;
            this.setState({ totalChild: sumPriceChild, child: newChild, totalPrice: total });
        }
        else if (price === 0 && checkAllotment === 400) {
            this.setState({ totalChild: 0, child: 0, totalPrice: 0 });
        }
        else {
            let newChild = 1;
            total = newChild * price;
            this.setState({ totalChild: total, child: newChild, totalPrice: total });
        }
    }

    subtractChild(price) {
        let { adult, child, totalAdult, totalPrice } = this.state;
        let sumPriceChild = 0
        let countChild = child - 1;
        if (countChild === 0 && adult === 0) {
            this.setState({ child: 0, totalChild: 0, totalPrice: 0 })
        }
        else if (countChild > 0 && adult === 0) {
            let total = countChild * price;
            this.setState({ totalChild: total, child: countChild, totalPrice: total })
        }
        else if (countChild > 0 && adult !== 0) {
            sumPriceChild = countChild * price;
            let total = (countChild * price) + totalAdult;
            this.setState({ totalChild: sumPriceChild, child: countChild, totalPrice: total })
        }
        else if (countChild === 0 && adult !== 0) {
            sumPriceChild = 0 * price;
            let total = sumPriceChild + totalAdult;
            this.setState({ totalChild: 0, child: 0, totalPrice: total })
        }
        else if (countChild < 0) {
            this.setState({ totalChild: 0, child: 0, totalPrice: totalPrice })
        }
    }

    handleDayChange(selectedDay, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        this.setState({
            selectedDay: input.value.trim(),
            isEmpty: !input.value.trim(),
            isValidDay: typeof selectedDay !== 'undefined',
            isDisabled: modifiers.disabled === true,
        });
        //Fetch price
        this.fetchAllotment(input.value.trim());
    }

    handleAddToCard(product_id, product_ref, required_pickup, required_dropoff, product_category) {
        let localStoragePickUp = JSON.parse(localStorage.getItem('pickUp'));
        let localStorageDropOff = JSON.parse(localStorage.getItem('dropOff'));

        let { adult, child, totalPrice, selectedDay, checkAllotment } = this.state;
        if (adult === 0 && child === 0 && totalPrice === 0 && checkAllotment === 400) {
            this.setState({ isValid: true, message: "No Available Product Allotment" })
        }
        else if (adult === 0 && child === 0 && totalPrice === 0) {
            this.setState({ isValid: true, message: "adult or child must be between 1 and 50" })
        }
        else if (required_pickup === true && required_dropoff === true) {
            if (localStoragePickUp && localStoragePickUp[product_ref] && localStorageDropOff && localStorageDropOff[product_ref]) {
                this.setState({ isValid: false })
                history.push(`/booking/cart/${product_id}`);
                this.props.productInfoFetch({
                    ...this.props.data, adult, child, totalPrice, localStoragePickUp, localStorageDropOff, product_category, travelDate: selectedDay
                })
            }
            else if (localStoragePickUp && !localStoragePickUp[product_ref]) {
                this.setState({ isValid: true, message: `Please Select PickUp At Product Ref ID ${product_ref}` })
            }
            else if (localStorageDropOff && !localStorageDropOff[product_ref]) {
                this.setState({ isValid: true, message: `Please Select DropOff At Product Ref ID ${product_ref}` });
            }
            else if (!localStoragePickUp && !localStorageDropOff) {
                this.setState({ isValid: true, message: "Please Select PickUp And DropOff" });
            }
            else if (!localStoragePickUp) {
                this.setState({ isValid: true, message: `Please Select PickUp At Product Ref ID ${product_ref}` });
            }
            else if (!localStorageDropOff) {
                this.setState({ isValid: true, message: `Please Select DropOff At Product Ref ID ${product_ref}` });
            }
        }
        else if (required_pickup === true && required_dropoff === false) {
            if (localStoragePickUp && localStoragePickUp[product_ref]) {
                this.setState({ isValid: false })
                history.push(`/booking/cart/${product_id}`);
                this.props.productInfoFetch({
                    ...this.props.data, adult, child, totalPrice, localStoragePickUp, localStorageDropOff, product_category, travelDate: selectedDay
                })
            }
            else if (localStoragePickUp && !localStoragePickUp[product_ref]) {
                this.setState({ isValid: true, message: `Please Select PickUp At Product Ref ID ${product_ref}` })
            }
            else if (!localStoragePickUp) {
                this.setState({ isValid: true, message: `Please Select PickUp At Product Ref ID ${product_ref}` });
            }
        }
        else if (required_pickup === false && required_dropoff === true) {
            if (localStorageDropOff && localStorageDropOff[product_ref]) {
                this.setState({ isValid: false })
                history.push(`/booking/cart/${product_id}`);
                this.props.productInfoFetch({
                    ...this.props.data, adult, child, totalPrice, localStoragePickUp, localStorageDropOff, product_category, travelDate: selectedDay
                })
            }
            else if (localStorageDropOff && !localStorageDropOff[product_ref]) {
                this.setState({ isValid: true, message: `Please Select DropOff At Product Ref ID ${product_ref}` })
            }
            else if (!localStorageDropOff) {
                this.setState({ isValid: true, message: `Please Select DropOff At Product Ref ID ${product_ref}` });
            }
        }
        else if (required_pickup === false && required_dropoff === false) {
            this.setState({ isValid: false })
            history.push(`/booking/cart/${product_id}`);
            this.props.productInfoFetch({
                ...this.props.data, adult, child, totalPrice, localStoragePickUp, localStorageDropOff, product_category, travelDate: selectedDay
            })
        }
    }

    closeModal() {
        this.setState({ isValid: false })
    }
    render() {
        const { data } = this.props;
        const FORMAT = 'YYYY-MM-DD';
        const { selectedDay, isDisabled, isEmpty, netAdult, netChild, totalPrice, isValid,
            message, child, adult } = this.state;
        return (
            <tr className="tr-createBooking" key={data.product_key}>
                <td className="td-product_ref">{data.product_ref}</td>
                <td className="td-productName">
                    <div className="box-productName">
                        {data.product_title}
                        <PickUpDropOff productRef={data.product_ref} />
                    </div>

                </td>
                <td className="td-input">
                    <div className="boxCreateBooking-DatePicker">
                        {!isEmpty && !selectedDay ? <p>This day is invalid</p> : null}
                        {selectedDay && isDisabled ? <p>This day is disabled</p> : null}
                        <div className="input-date-createbooking">
                            <div className="input-group-icon"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                            <div className="input-group-area">
                                <div className="InputFrom">
                                    <DayPickerInput
                                        placeholder="YYYY-MM-DD"
                                        format={FORMAT}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        value={selectedDay}
                                        onDayChange={this.handleDayChange}
                                        dayPickerProps={{
                                            disabledDays: { before: new Date() },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td className="td-people">
                    <div className="box-adult">
                        <div className="icon-Adult">
                            <FontAwesomeIcon icon={faUserAlt} />
                        </div>
                        <div className="number" >
                            {adult}
                        </div>
                        <div className="add">
                            <button className="button-people" onClick={() => this.addAdult(netAdult)}>+</button>
                        </div>
                        <div className="subtrack">
                            <button className="button-people" onClick={() => this.subtractAdult(netAdult)}>-</button>
                        </div>
                    </div>
                </td>
                <td className="td-people">
                    <div className="box-child">
                        <div className="icon-child">
                            <FontAwesomeIcon icon={faUserAlt} />
                        </div>
                        <div className="number" >
                            {child}
                        </div>
                        <div className="add">
                            <button className="button-people" onClick={() => this.addChild(netChild)}>+</button>
                        </div>
                        <div className="subtrack">
                            <button className="button-people" onClick={() => this.subtractChild(netChild)}>-</button>
                        </div>
                    </div>
                </td>
                <td style={{ textAlign: "center" }}>{netAdult}</td>
                <td style={{ textAlign: "center" }}>{netChild}</td>
                <td style={{ textAlign: "center" }}>{totalPrice}</td>
                <td className="td-button">
                    <button className="button-card" onClick={() => this.handleAddToCard(data.product_id, data.product_ref, data.required_pickup, data.required_dropoff, data.product_category)}>
                        Add to Card
                    </button>
                    <ModalAlert message={message} action={isValid} onClick={this.closeModal} />
                </td>
            </tr >
        );
    }
}
function mapStateToProps({ productInfo }) {
    return { productInfo };
}

export default connect(mapStateToProps, { productInfoFetch })(SubTable);